import React from "react";
import shape_bg_blue from "../../images/sahpe-bg-blue.svg";
import tornament_slider from "../../images/tornament-slider.png";
import club_lg from "../../images/club-lg.svg";
import Rumble_lg from "../../images/rumble-lg.svg";
import heads_lg from "../../images/heads-lg.svg";
import Rumble_Satellitelg from "../../images/Rumble-Satellitelg.svg";
import Header from "../Header";
import { getAuth } from "../../Routes/Routes";
import Footer from "../Footer";
import ScrollToTop from "../../hooks/ScrollToTop";
import { profileToggleAction } from "../../store/actions/toggleAction";
import { useDispatch } from "react-redux";

const TournamentRule = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const showModel = () => {
    setModalShow(!modalShow);
  };
  return (
    <div>
      <Header auth={getAuth} />
      <section
        className="slider-inner-main play-slidermain"
        onClick={() => dispatch(profileToggleAction(false))}
      >
        <div className="custom-container">
          <div className="inner-slidrbx">
            <div className="row align-items-center">
              <div className="col-md-6 one">
                <div className="left-texsldr">
                  <div className="subtitle-bx">
                    <span className="smal-line" />
                    <span className="subtitle">TOURNAMENTS AND RULES</span>
                  </div>
                  <div className="heading-topp">
                    <h1 className="title-large">
                      HOW FX RUMBLE TOURNAMENTS WORK
                    </h1>
                    <p className="medium-text box-space">
                      We offer different kind of tournaments and more will come
                      in the coming months! Here you can learn about the rules,
                      watch video tutorials about our dashboard and how the
                      rewards system works.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 two">
                <div className="right-pic-sldr">
                  <img src={tornament_slider} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-btom">
          <img src={shape_bg_blue} alt="" />
        </div>
      </section>
      <section className="play-accord-section tournament-bxnew">
        <div className="container">
          <div className="playmain-box">
            <div className="heading-main">
              <h4>
                <span className="title-medium white-br-botm">
                  OUR TOURNAMENTS
                </span>
              </h4>
              <p>
                Here you can find all the rules of the different style of
                tournaments that we offer. Learn about the rules and the
                workings of the different matches. We will release new kind of
                tournaments every month.
              </p>
            </div>

            <div className="tournamt-cardrumb">
              <div className="row">
                <div className="col-6 one">
                  <a to="" className="logo-rumblebx" onClick={showModel}>
                    <div className="card-rumblogo">
                      <img src={heads_lg} alt="" />
                    </div>
                  </a>
                </div>
                <div className="col-6 one">
                  <a to="" className="logo-rumblebx" onClick={showModel}>
                    <div className="card-rumblogo">
                      <img src={club_lg} alt="" />
                    </div>
                  </a>
                </div>
                <div className="col-6 one">
                  <a to="" className="logo-rumblebx" onClick={showModel}>
                    <div className="card-rumblogo">
                      <img src={Rumble_Satellitelg} alt="" />
                    </div>
                  </a>
                </div>
                <div className="col-6 one">
                  <a to="" className="logo-rumblebx" onClick={showModel}>
                    <div className="card-rumblogo">
                      <img src={Rumble_lg} alt="" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollToTop />
      {/* Modal */}
      <div
        className={`modal  ${
          modalShow ? "d-block" : ""
        } tournament-popup tournament-rules`}
        id="exampleModalCenter"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => setModalShow(false)}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Tournament Rules
              </h5>
              <div className="tournament-popup">
                <ul className="modal-listing">
                  <li>Setting the Trading Dashboard</li>
                  <li>Enter a tournament or create your own</li>
                  <li>How to analyze your statistics</li>
                  <li>Watch a tournament simulation</li>
                  <li>How the tournaments work</li>
                  <li>How to register, deposit, withdrawal</li>
                </ul>
              </div>
              <div className="btn-bottom-list text-center">
                <a href="/all-tournaments" className="btn-org btn-bxx-smll">
                  PLAY NOW
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentRule;
