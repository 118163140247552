import React from "react";
import enter_login from "../../images/enter-login.svg";
import iconmonstr_email from "../../images/iconmonstr-email.svg";
import iconmonstr_lock from "../../images/iconmonstr-lock.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { postRequest }  from '../../Routes/axios'

const LoginForm = () => {
  const history = useHistory();
  const [error, setError] = React.useState({
    email: null,
    password: null,
  });
  const [values, setValues] = React.useState({
    email: "",
    password: "",
  });
  const [checked, setChecked] = React.useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setError({
      ...error,
      [name]: null,
    });
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleLogin = async () => {
    try {
      if (validate()) {
        let res = await postRequest(`/login`, {
          email: values.email,
          password: values.password,
        });
        if(res && res.data) {
          localStorage.setItem("auth-token", JSON.stringify(res.data.token));
          localStorage.setItem("FXuser", JSON.stringify(res.data.user));
          if (res.data.success === true) {
            let path = localStorage.getItem("path");
            if (path) {
              localStorage.removeItem("path");
              return history.push("/create-tournament", { replace: true });
            } else {
              return history.push("/profile", { replace: true });
            }
          }
        }
      } else {
        console.log('\n not valid')
      }

    } catch (err) {
      console.error(err?.response?.data ||  err.message || err);
      // beow section not valid into catch block need to modify error set section later
          if (err?.response?.data?.name === "email") {
            setError({
              ...error,
              email: err?.response?.data?.msg,
            });
          }
          if (err?.response?.data?.name === "password") {
            setError({
              ...error,
              password: err?.response?.data?.msg,
            });
          }

    }
  };
  const validate = () => {
    let input = values;
    let errors = {};
    let isValid = true;

    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }

    if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }

    setError({
      ...errors,
    });
    return isValid;
  };
  return (
    <div className="form-filedbx">
      <div className="form-group">
        <div className="frm-bx-fldn">
          <input
            type="email"
            value={values.email}
            name="email"
            onChange={handleChange}
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Email"
          />
          <span className="icon-form-fld">
            <img src={iconmonstr_email} alt="" />
          </span>
        </div>
        {error.email && <span className="error">{error.email}</span>}
      </div>
      <div className="form-group">
        <div className="frm-bx-fldn">
          <input
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            className="form-control"
            id="password"
            placeholder="Password"
          />
          <span className="icon-form-fld">
            <img src={iconmonstr_lock} alt="" />
          </span>
        </div>
        {error.password && <span className="error">{error.password}</span>}
      </div>

      <div className="form-group">
        <div className="frm-bx-fldn">
          <button
            type="button"
            name="button"
            onClick={handleLogin}
            className="btn-logn-frm btn-org"
          >
            <span className="icon-btnn">
              <img src={enter_login} alt="" />
            </span>
            <span className="btn-tex">Login</span>
          </button>
        </div>
      </div>

      <div className="forgt-lnk">
        <Link to="/forgotpassword">Forgot Password?</Link>
      </div>
    </div>
  );
};

export default LoginForm;
