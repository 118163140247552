import { fetchEndpointData }  from '../../Routes/axios'

export const getUser = () => {
  return async (dispatch) => {
    try {
      const response = await fetchEndpointData('/user')
      console.log('\n getUser response:', response)
      if(response && response?.status && response.status === 200) {
        return dispatch({
          type: "GET_USER",
          payload: response.data.data,
        });
      }
    } catch (error) {
      console.log('\n error in getUser:', error.message || error)
      //localStorage.removeItem("auth-token")
      return dispatch({
        type: "GET_USER_ERROR",
        payload: false
      });
    }
  };
};
