import React from "react";
import { getAuth } from "../../Routes/Routes";
import Footer from "../Footer";
import Header from "../Header";
import { useHistory } from "react-router-dom";

import raxicon from "../../images/rxicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/actions/userActions";
import { getTournament } from "../../store/actions/tournamentActions";
import { buyPlan, getCard, Loading } from "../../store/actions/stripeActions";
import AddCoinPopUp from "../AddCoinPopUp";
import ScrollToTop from "../../hooks/ScrollToTop";
import { postRequestWithErrorHandling }  from '../../Routes/axios'
import { profileToggleAction } from "../../store/actions/toggleAction";
const config = require(`../../enviorment/${process.env.NODE_ENV}.jsx`).config;

const Wallet = () => {
  const [modal, setModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [coin, setCoin] = React.useState('');
  let navigate = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const { about, email, fullName, fxCoin, id, image } = state.user?.user;
  let { loading, buyPlanData, cards } = state?.stripe;
  React.useEffect(() => {
    dispatch(getUser());
    dispatch(getCard());
    let user = localStorage.getItem("FXuser");
    dispatch(getTournament(JSON.parse(user).email));
  }, []);

  const handleSubmit = async () => {
    try {
      dispatch(Loading(true));
      let res = await postRequestWithErrorHandling(`/stripe/buy`, {
        price: parseInt(coin),
      });
      if(res && res.data) {
        dispatch(buyPlan(res.data));
        return history.push("/successfully");
      }
    } catch (error) {
      setErrorMsg(error.message || 'Something went wrong during add amount')
      dispatch(Loading(false));
      console.log('\n error in submit:', error)
    }
  };

  React.useEffect(() => {
    if(errorMsg) return;
    setModal(loading);
    setCoin(null);
    dispatch(getUser());
  }, [state.stripe]);

  return (
    <>
      <Header auth={getAuth} />
      <section className="innerpage-bx wallet-dashboard" onClick={()=>dispatch(profileToggleAction(false))}>
        <div className="club-logo-rxrbl">
          <img src="images/blueclub-logo.png" alt="" />
        </div>
        <div className="container">
          <div className="wallet-history">
            <div className="wallet-balance">
              <div className="title-wallet-lg">Wallet</div>
              <div className="wallet-balan-card">
                <div className="current-balnc-inner">
                  <div className="wallet-card boxcard-wallet">
                    <div className="texsml">Current Balance</div>
                    <div className="wallet-coin">
                      <span className="fxicn">
                        <img src={raxicon} alt="" />
                      </span>
                      <span className="coin-total">{fxCoin ?? 0.0}</span>
                    </div>
                    <div className="withdrwl-btnn">
                      <ul>
                        <li>
                          <button
                            className="btn-org btn-bxx btn-primaryy"
                            data-toggle="modal"
                            data-target="#exampleModal"
                            onClick={() => {
                              if (cards?.length > 0) setModal(true);
                              else return navigate.push("/add-card");
                            }}
                          >
                            Add Money
                          </button>
                        </li>
                        <li>
                          <button
                            disabled
                            to=""
                            className="loginbtn btn-bxx btn-primaryy"
                          >
                            Withdrawal
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wallet-history-table">
              <div className="tabel-history">
                <div className="title-wallet-lg">Wallet History</div>
                <div className="history-tabel-main">
                  <div className="tabelbox-his table-responsive">
                    <table className="table table-striped-even table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Transaction ID</th>
                          <th scope="col">Date</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>#1234</td>
                          <td className="date">19-aug-2021</td>
                          <td>$243</td>
                          <td>
                            <div className="btn-satus">
                              <a to="" className="added-btn-status">
                                Added
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>#6547</td>
                          <td className="date">19-aug-2021</td>
                          <td>$243</td>
                          <td>
                            <div className="btn-satus">
                              <a to="" className="added-btn-status light-gren">
                                Withdrawal
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>#5467</td>
                          <td className="date">19-aug-2021</td>
                          <td>$243</td>
                          <td>
                            <div className="btn-satus">
                              <a to="" className="added-btn-status">
                                Added
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>#8975</td>
                          <td className="date">19-aug-2021</td>
                          <td>$243</td>
                          <td>
                            <div className="btn-satus">
                              <a to="" className="added-btn-status">
                                Withdrawal
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddCoinPopUp
        handleSubmit={() => handleSubmit()}
        loading={loading}
        modal={modal}
        coin={coin}
        setCoin={(val) => setCoin(val)}
        setModal={(val) => setModal(val)}
        errorMsg={errorMsg}
        setErrorMsg={(val)=> setErrorMsg(val)}
      />
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default Wallet;
