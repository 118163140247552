import React, { memo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import participate2 from "../../images/participate2.png";
import rxicon from "../../images/rxicon.svg";
import small_club_rmb from "../../images/small-club-rmb.svg";
import small_headsup_rmb from "../../images/small-headsup.svg";
import moment from "moment";
import { getAuth } from "../../Routes/Routes";
//import Highcharts from "highcharts/highstock";
//import HighchartsReact from "highcharts-react-official";
import { useDispatch, useSelector } from "react-redux";
import {
  creditCoin,
  getSingleTournament,
  getTournament,
  getTournamentParticipants,
} from "../../store/actions/tournamentActions";
import GraphSideBar from "../GraphSideBar";
import Header from "../Header";
//import { setDate } from "date-fns";
import { TVChartContainer } from "../TVChartContainer/index";

import { fetchEndpointData, postRequest } from "../../Routes/axios";
import { profileToggleAction } from "../../store/actions/toggleAction";

const PlayGround = (props) => {
  const tournament = useSelector((state) => state.tournament);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  let { participants, singleTournament, isActive } = tournament;
  const [seconds2, setSeconds2] = useState(10000);
  const [seconds, setSeconds] = useState(10000);
  const [transactionList, setTransactionsList] = useState([]);
  const [coinData, setCoinData] = useState({
    time: 0,
    open: 0,
    high: 0,
    low: 0,
    close: 0,
    volume: 0,
  });
  const [initialLoad, setInitialLoad] = useState(false)
  const [qty, setQty] = useState(1);
  const [base_bal, setBaseBal] = useState(0);
  const [other_bal, setOtherBal] = useState(0);
  const startdatetime = localStorage.getItem("StartTournamentDate");
  let { id, type, name } = props.match.params;

  let startTimenew = moment().subtract("20", "minutes");
  useEffect(async () => {
    async function fetchInfo() {
      try {
        let user = localStorage.getItem("FXuser");
        if (user) {
          dispatch(getTournament(JSON.parse(user).email, name));
          dispatch(getTournamentParticipants({ payload: id, name }));
          transactions();
          const single_tournamet = await dispatch(
            getSingleTournament({ payload: id, name })
          );
          startTimenew = single_tournamet.payload.startDateTime;
          let time = single_tournamet.payload.duration.split(" ")[1];
          let sec =
            time === "h"
              ? parseInt(single_tournamet.payload.duration.split(" ")[0]) *
                60 *
                60 *
                1000
              : parseInt(single_tournamet.payload.duration.split(" ")[0]) *
                60 *
                1000;
          let date =
            new Date(single_tournamet.payload.startDateTime).getTime() + sec;
          let date2 = new Date(single_tournamet.payload.startDateTime);
          setSeconds(date);
          setSeconds2(date2);
        }
      } catch (err) {
        console.log("\n fetchInfo error:", err.message || err);
      }
    }
    fetchInfo();
  }, [startdatetime]);
  useEffect(() => {
    let transactions = participants?.find(
      (x) => x.email === JSON.parse(localStorage.getItem("FXuser"))?.email
    );
    setOtherBal(transactions?.transactions?.other_bal);
    setBaseBal(transactions?.transactions?.base_bal);
  }, [participants]);
  const history = useHistory();
  useEffect(()=> {
    try {
      if(initialLoad) {
        requestData();
      }
    } catch(e) {}
  },[initialLoad, setInitialLoad])
  async function requestData(payload) {
    try {
      let updatedPlaylod = {
        startTime: Number(moment(startTimenew).valueOf()),
        type: type,
      };
      let results = await postRequest(`/get-rates`, updatedPlaylod);
      if (results && results.data && results.data.status) {
        //console.log('\n requestData results:', results.data.data)
        setCoinData(results.data.data)
      }
    } catch (err) {
      console.log("\n err requestData:", err.message || err);
    }
  }

  const redirect = async () => {
    try {
      let endpoint = name ? "/admin-tournament" : "/tournament";
      let res = await postRequest(`${endpoint}/end-tran`, {
        tournament: id,
        pair: "EURUSD",
        price: coinData.close ? coinData.close : 0,
      });
      return history.push(`/results/${id}`);
    } catch (error) {
      console.log("error=======================", error);
      return history.push(`/results/${id}/:admin`);
    }
  };

  const transactions = async () => {
    try {
      let endpoint = name ? "/admin-tournament" : "/tournament";
      const res = await fetchEndpointData(`${endpoint}/transactions/${id}`);
      console.log("\n transactions:", res);
      if (res && res.data && res.data.data) {
        setTransactionsList(res.data.data);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      <Header auth={getAuth} />
      <section className="innerpage-bx" onClick={()=>dispatch(profileToggleAction(false))}>
        <div className="leaderbd-main">
          <div className="custom-row-leadership">
            <div className="left-sidebarr">
              <div className="leader-mainbx">
                <div className="heading">
                  <div className="label-bx">Leaderboard</div>
                  <div className="total-participate">
                    {participants && participants[0]?.participantsCount}
                    Participants
                  </div>
                </div>
                <div className="list-leaderboard-box">
                  {participants?.map((x, index) => {
                    return (
                      <div className="listing-leaderbx" key={x.id}>
                        <div className="card-listbx">
                          <div className="imagbx">
                            <img src={x.image ?? participate2} alt="" />
                          </div>
                          <div className="name-cardbx">
                            <div className="name">
                              {x.fullName.slice(0, 20)}
                            </div>
                            <div className="point-leaderbrd">
                              <span className="iconfx">
                                <img src={rxicon} alt="" />
                              </span>
                              <span className="up-price">
                                {x.transactions?.base_bal}
                                <span className="uparrow">
                                  <i className="fas fa-long-arrow-alt-up" />
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="count-num">
                            <span className="total-num">{index + 1}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="center-mainbx">
              <div className="center-tarde-map">
                <div className="club-tournt-main">
                  <div className="leftbx">
                    <div className="club-main-tournmt">
                      <div className="heading-club">
                        {singleTournament?.type == "headsUp"
                          ? "Heads Up"
                          : "Club"}
                        Tournament
                      </div>
                      <div className="club-pic">
                        <img
                          src={
                            singleTournament?.type == "headsUp"
                              ? small_headsup_rmb
                              : small_club_rmb
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="right-symbol-drop pl-1">
                    <div className="curency-symbol d-flex">
                      <div className="form-group">
                        <label>Quantity Available</label>
                        <input
                          disabled
                          value={other_bal}
                          style={{
                            backgroundColor: "#233146",
                            outline: "none",
                            border: "none",
                            color: "#A3AEBC",
                          }}
                          className="form-control"
                          id="exampleQuantityAvailable"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="trading-map-contain">
                <TVChartContainer
                  type={type}
                  tournamentId={id}
                  startdatetime={startdatetime}
                  setCoinData={setCoinData}
                  initialLoad={initialLoad}
                  setInitialLoad={setInitialLoad}
                />
                {/* <div className="map-detail">
                  <HighchartsReact
                    x
                    highcharts={Highcharts}
                    constructorType={"stockChart"}
                    options={{
                      chart: {
                        renderTo: "container",
                      },
                      xAxis: {
                        type: "datetime",
                        // events: {
                        //   setExtremes: (e) => graphDate(e),
                        // },
                      },
                      plotOptions: {
                        candlestick: {
                          color: "red",
                          upColor: "green",
                        },
                      },
                      series: [
                        {
                          type: "candlestick",
                          data: data,
                          color: "#0066FF",
                        },
                        {
                          name: "BID",
                          color: "#228C22",
                          data: bid,
                        },
                        {
                          name: "ASK",
                          color: "#FF0000",
                          data: ask,
                        },
                      ],
                    }}
                  />
                </div> */}
              </div>
              <div className="center-tarde-map">
                <div className="tabelbox-his table-responsive ongoing-tablee">
                  <table className="table table-striped-even table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" className="one">
                          Transaction Id
                        </th>
                        <th scope="col" className="two">
                          Buy/Sell Price
                        </th>
                        <th scope="col" className="two">
                          Quantity
                        </th>
                        <th scope="col" className="three">
                          Date-Time
                        </th>
                        <th scope="col" className="four">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionList?.map((x) => {
                        return (
                          <tr key={x.id}>
                            <td className="tournament-name">
                              #{x.transactionId}
                            </td>
                            <td>{x.price}</td>
                            <td>{x.qty}</td>
                            <td>{new Date(x.createdAt).toLocaleString()}</td>
                            <td>{x.tran_type}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <GraphSideBar
              askPrice={coinData?.close}
              bidPrice={coinData?.close}
              error={error}
              qty={qty}
              redirect={redirect}
              seconds={seconds}
              seconds2={seconds2}
              param={{
                id: id,
                name: name,
                base_bal: base_bal,
                other_bal: other_bal,
              }}
              setTransactionsList={setTransactionsList}
            />
          </div>
        </div>
      </section>
    </>
  );
};

//export default memo(PlayGround);
export default PlayGround;
