import React from "react";
import { Link, useHistory } from "react-router-dom";
import profile_pic from "../../images/dummy.png";
import pencil from "../../images/pencil.png";
import sahpe_bg_blue from "../../images/sahpe-bg-blue.svg";
import headsUp from "../../images/heads-up.svg";
import email_new from "../../images/email-new.svg";
import rumble from "../../images/rumble.svg";
import club from "../../images/club.svg";
import Header from "../Header";
import { getAuth } from "../../Routes/Routes";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/actions/userActions";
import { getTournament } from "../../store/actions/tournamentActions";
import { buyPlan, getCard, Loading, fetchAllBank } from "../../store/actions/stripeActions";
import WalledSideBar from "../WalledSideBar";
import AddCoinPopUp from "../AddCoinPopUp";
import ScrollToTop from "../../hooks/ScrollToTop";
import { postRequestWithErrorHandling }  from '../../Routes/axios'
import { profileToggleAction } from "../../store/actions/toggleAction";

const config = require(`../../enviorment/${process.env.NODE_ENV}.jsx`).config;

const Profile = () => {
  const [modal, setModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [coin, setCoin] = React.useState('');
  let history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const { about, email, fullName, fxCoin, id, image } = state.user?.user;
  
  React.useEffect(() => {
    dispatch(getUser());
    dispatch(getCard());
    dispatch(fetchAllBank());
    let user = localStorage.getItem("FXuser");
    dispatch(getTournament(JSON.parse(user).email));
  }, []);

  let { loading, buyPlanData, cards } = state?.stripe;
  const handleSubmit = async () => {
    try {
      dispatch(Loading(true));
      let res = await postRequestWithErrorHandling(`/stripe/buy`, {
        price: parseInt(coin),
      });
      if(res && res.data) {
        dispatch(buyPlan(res.data));
        return history.push("/successfully");
      }
    } catch (error) {
      setErrorMsg(error.message || 'Something went wrong during add amount')
      dispatch(Loading(false));
      console.log('\n error in submit:', error)
    }
  };

  React.useEffect(() => {
    if(errorMsg) return;
    console.log('\n on change sripe reducer:', loading)
    setModal(loading);
    setCoin(null);
    dispatch(getUser());
  }, [state.stripe]);

  return (
    <>
      <Header auth={getAuth} />
      <div className="wrapper" onClick={()=>dispatch(profileToggleAction(false))}>
        <section className="profile-slider">
          <div className="container">
            <div className="profile-mainbx">
              <div className="custom-row">
                <div className="profile-left">
                  <div className="prifle-detail">
                    <div className="profile-img">
                      <div className="pic-proflnew">
                        <img src={image ?? profile_pic} alt="" />
                      </div>
                      <div className="profile-detal-content">
                        <div className="name">{fullName}</div>
                        <div className="email-detail">
                          <span className="iconmail">
                            <img src={email_new} alt="" />
                          </span>
                          <span className="texx">{email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profile-edit-right">
                  <div className="btn-editt">
                    <Link to="/edit-profile" className="btn-org btn-bxx-smll">
                      <span className="icon-btnn">
                        <img src={pencil} alt="" />
                      </span>
                      <span className="btn-tex">Edit</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-btom">
            <img src={sahpe_bg_blue} alt="" />
          </div>
        </section>

        {/* <!-- ===== slider section end========--> */}

        <section className="profile-about">
          <div className="container">
            <div className="profile-abt-boxx">
              <div className="row">
                <div className="col-md-7 col-lg-8 one">
                  <div className="left-detail-bxx">
                    <div className="detal-card-tournament">
                      <h3 className="title-card">About me</h3>
                      <p
                        className="small-text"
                        style={{ wordBreak: "break-all" }}
                      >
                        {about ?? "Please enter details"}
                      </p>
                    </div>

                    {state?.tournament?.tournaments?.length > 0 && (
                      <div className="play-tournmt-bbx">
                        <div className="play-tournment-section">
                          <h3 className="title-card">Playing Tournament</h3>
                          <div className="cardsection-tornmt">
                            <ul>
                              {state?.tournament?.tournaments?.map((x, index) => {
                                return (
                                  <li key={`l-${index}`}>
                                    <Link to="">
                                      <div className="iconbx">
                                        <img
                                          src={
                                            x.type === "club"
                                              ? club
                                              : x.type === "headsUp"
                                              ? headsUp
                                              : rumble
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <WalledSideBar
                  state={state}
                  cards={cards}
                  fxCoin={fxCoin}
                  setModal={(val) => setModal(val)}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <AddCoinPopUp
        handleSubmit={() => handleSubmit()}
        loading={loading}
        modal={modal}
        coin={coin}
        setCoin={(val) => setCoin(val)}
        setModal={(val) => setModal(val)}
        errorMsg={errorMsg}
        setErrorMsg={(val)=> setErrorMsg(val)}
      />
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default Profile;
