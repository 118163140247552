import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import logo from "../../images/logo.svg";
import { getAuth } from "../../Routes/Routes";
import { profileToggleAction } from "../../store/actions/toggleAction";
import Footer from "../Footer";
import Header from "../Header";

const LinkSent = () => {
  const dispatch=useDispatch();
  return (
    <>
      <Header auth={getAuth} />
      <div className="wrapper" onClick={()=>dispatch(profileToggleAction(false))}>
        <section className="login-box">
          <div className="container-login-bx">
            <div
              className="section-main-cardd"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              <div className="row">
                <div className="col-md-6 col-lg-7 one">
                  <div className="welcm-bx">
                    <h4 className="medium-subtitle">Welcome to</h4>
                    <h1 className="title-large">FX RUMBLE</h1>
                    <p>Sed do eiusmod tempor incididunt</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 two">
                  <form>
                    <div className="login-form-new">
                      <div className="form-logn-bx">
                        <div className="logo-card">
                          <Link to="">
                            <img src={logo} alt="" />
                          </Link>
                        </div>
                        <div className="login-detail-frm form-layoutt change-passwd">
                          <div className="tickcrcl">
                            <div className="anim-tick">
                              <svg
                                version="1.1"
                                id="tick"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 37 37"
                                style={{ enableBackground: "new 0 0 37 37" }}
                                xmlSpace="preserve"
                              >
                                <path
                                  className="circ path"
                                  style={{
                                    fill: "none",
                                    stroke: "#fff",
                                    strokeWidth: "2",
                                    strokeLineJoin: "round",
                                    strokeMiterLimit: "10",
                                  }}
                                  d="
                              	M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
                                />
                                <polyline
                                  className="tick path"
                                  style={{
                                    fill: "none",
                                    stroke: "#fff",
                                    strokeWidth: "2",
                                    strokeLineJoin: "round",
                                    strokeMiterLimit: "10",
                                  }}
                                  points="
                              	11.6,20 15.9,24.2 26.4,13.8 "
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="headg-bx-lgn forgot-pssw">
                            <h4 className="heading-frmm grey-clr">Success!</h4>
                            <p className="text">
                              Your password reset link has been sent
                              successfully.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default LinkSent;
