import React, { useEffect, useRef } from "react";
import club from "../../images/club.svg";
import headsUp from "../../images/heads-up.svg";
import { Link } from "react-router-dom";
import Header from "../Header";
import { getAuth } from "../../Routes/Routes";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchEndpointData } from "../../Routes/axios";
import KycIcon from "../../images/kyc_icon.svg";
import {
  getTournament,
  joinTournament,
} from "../../store/actions/tournamentActions";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import { profileToggleAction } from "../../store/actions/toggleAction";

const OnGoingTournament = (props) => {
  const tournament = useSelector((state) => state.tournament);
  let { tournaments, adminTournament, singleTournament } = tournament;
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const [invitationModal, setInvitationModal] = React.useState(false);
  const [kycStatus, setKycStatus] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  let user = JSON.parse(localStorage.getItem("FXuser"));
  let history = useHistory();
  useEffect(async () => {
    if (props.match.params.id) {
      console.log(props.match.params.id);
      // let user = JSON.parse(localStorage.getItem("FXuser"));
      dispatch(
        joinTournament({ id: props.match.params.id, email: user?.email })
      );
    }
    const checkstatus = async () => {
      const { data } = await fetchEndpointData(
        `/kyc/applicants/status?userId=${user.applicantId}`
      );
      if (
        data?.response?.reviewStatus == "completed" &&
        user.applicantId !== null
      ) {
        setKycStatus(false);
        setOpen(false);
      } else {
        setOpen(true);
      }

      if (user.applicantId == null) {
        setMessage(
          "First, complete your Verify Me application.<br> <small>You  can access the tournament.</small>"
        );
      }
      if (data?.response?.reviewStatus) {
        if (data?.response?.reviewStatus == "init") {
          setMessage(
            "First, complete your Verify Me application.<br> <small>You  can access the tournament.</small>"
          );
        }
        if (data?.response?.reviewStatus == "pending") {
          setMessage(
            "The approval of your KYC application is pending.<br> <small>You can't access the tournament.</small>"
          );
        }
      }
    };
    checkstatus();
  }, []);
  React.useEffect(async () => {
    let user = localStorage.getItem("FXuser");
    dispatch(getTournament(JSON.parse(user).email));
    // window.scrollTo(0, 0);
  }, []);
  const selectedTournamentRef = useRef({
    selectedId: null,
    selectedTradableMartket: null,
  });
  const handleClick = ({ name, id, tradableMartket }) => {
    try {
      let user = JSON.parse(localStorage.getItem("FXuser"));
      const selectedTournament = {
        selectedId: id,
        selectedTradableMartket: tradableMartket,
      };
      console.log("selectedTournament", selectedTournament);
      selectedTournamentRef.current = selectedTournament;
      dispatch(joinTournament({ id: id, email: user?.email, name: name }));
    } catch (err) {
      console.log("\n handleClick:", err.message || err);
    }
  };
  const joinTournamentSelector = useSelector(
    (state) => state.tournament.newJoinedTournament
  );
  useEffect(() => {
    if (joinTournamentSelector) {
      console.log(
        "\n  joinTournamentSelector",
        joinTournamentSelector,
        "selectedTournamentRef:",
        selectedTournamentRef
      );
      history.push(
        `/playground/${selectedTournamentRef.current.selectedId}/${selectedTournamentRef.current.selectedTradableMartket}`
      );
    }
    return () => {
      dispatch({
        type: "JOIN_TOURNAMENT",
        payload: null,
      });
    };
  }, [joinTournamentSelector]);
  console.log(kycStatus, "kycStatuskycStatuskycStatus");

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  return (
    <div>
      <Header auth={getAuth} />
      <div className="wrapper">
        <section
          className="innerpage-bx ongoing-tourn-dashboard"
          onClick={() => dispatch(profileToggleAction(false))}
        >
          <div className="container">
            <div className="ongoing-tournament-table">
              <div className="wallet-history-table">
                <div className="tabel-history">
                  <div className="heading-top">PLAY NOW</div>
                  <div className="history-tabel-main">
                    <div className="tabelbox-his table-responsive ongoing-tablee">
                      <table className="table table-striped-even table-borderless">
                        <thead>
                          <tr>
                            <th scope="col" className="one">
                              Tournament Name
                            </th>
                            <th scope="col" className="two">
                              Total Participants
                            </th>
                            <th scope="col" className="three">
                              People Join
                            </th>
                            <th scope="col" className="four">
                              Start Time
                            </th>
                            <th scope="col" className="five">
                              Create By
                            </th>
                            <th scope="col" className="six">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tournaments?.map((x, index) => {
                            return (
                              <tr key={`t-${index}`}>
                                <td className="tournament-name" key={x.id}>
                                  <div className="tournament-pic-bx">
                                    <div className="img-bx">
                                      <img src={x.type} alt="" />
                                      <span className="name-club">
                                        {x.type} Tournament
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>{x.participants}</td>
                                <td>
                                  {x?.result?.filter((a) => a.status === true)
                                    ?.length > 0
                                    ? x?.result?.filter(
                                        (a) => a.status === true
                                      )?.length
                                    : 0}
                                </td>
                                <td>
                                  {new Date(x.startDateTime).toLocaleString()}
                                </td>
                                <td>{x.createdBy}</td>
                                <td>
                                  <div
                                    className="btn-satus ss"
                                    // onClick={()=> window.location.reload(true) }
                                  >
                                    {new Date().getTime() <
                                      new Date(x.endTime).getTime() &&
                                    new Date().getTime() >
                                      new Date(x.startDateTime).getTime() ? (
                                      <Link
                                        // href="javascript:"
                                        onClick={() =>
                                          handleClick({
                                            id: x.id,
                                            tradableMartket: x.tradableMartket,
                                          })
                                        }
                                        // to={`/playground/${x.id}/${x.tradableMartket}`}
                                        className="btn-org btn-bxx btn-primaryy"
                                      >
                                        Play Now
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                    {new Date().getTime() <
                                    new Date(x.startDateTime).getTime() ? (
                                      <button
                                        disabled={true}
                                        className="btn-org btn-bxx btn-primaryy"
                                      >
                                        UpComing
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {new Date().getTime() >
                                    new Date(x.endTime).getTime() ? (
                                      <button
                                        disabled={true}
                                        className="btn-org btn-bxx btn-primaryy"
                                      >
                                        Expired
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="create-bx">
                      <div className="createnow-btn">
                        <button
                          className="btn-createnw"
                          disabled={kycStatus}
                          onClick={() => setModalShow(true)}
                        >
                          Create Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        className={`modal  ${
          open ? "d-block" : ""
        } tournament-popup banner_modal`}
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => setOpen(false)}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="image-box">
                <img src={KycIcon} alt="iconbx"></img>
              </div>
              <div className="detail_box">
                <h5 className="title-sm">
                  <p dangerouslySetInnerHTML={{ __html: message }} />
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*===== create now popup =====*/}
      {/* Modal */}
      <div
        className={`modal  ${modalShow ? "d-block" : ""} tournament-popup`}
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => setModalShow(false)}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Select Tournament
              </h5>
              <div className="tournament-popup-list">
                <ul>
                  <li>
                    <Link to="/create-tournament/club" className="club-card">
                      <img src={club} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/create-tournament/headsUp" className="club-card">
                      <img src={headsUp} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal  ${
          invitationModal ? "d-block" : ""
        } tournament-popup`}
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  onClick={() => setInvitationModal(false)}
                >
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                You have a invitation for tournament
              </h5>
              <div className="tournament-popup-list">
                <ul>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default OnGoingTournament;
