import React from "react";
import { Link, useHistory } from "react-router-dom";
import card_bg from "../../images/card-bg.svg";
import raxicon from "../../images/rxicon.svg";
const WalledSideBar = ({ fxCoin, setModal, cards, state }) => {
  let history = useHistory();
  return (
    <div className="col-md-5 col-lg-4 two">
      <div className="right-list-wallet">
        <div className="wallet-card boxcard-wallet">
          <Link to="/wallet" className="title-card">
            Wallet
          </Link>
          <div className="texsml">Available Balance</div>
          <div className="wallet-coin">
            <span className="fxicn">
              <img src={raxicon} alt="" />
            </span>
            <span className="coin-total">{fxCoin ?? 0.0}</span>
          </div>

          <div className="withdrwl-btnn">
            <ul>
              <li>
                <Link to="" className="btn-org btn-bxx btn-primaryy">
                  Withdrawal
                </Link>
              </li>

              <li>
                <button
                  className="loginbtn btn btn-bxx btn-primaryy"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  onClick={() => {
                    if (cards?.length > 0) setModal(true);
                    else return history.push("/add-card");
                  }}
                >
                  Add More
                </button>
              </li>
            </ul>
          </div>
        </div>
        {state.stripe?.cards && (
          <div className="card-info boxcard-wallet">
            <div className="card-infobx">
              <h3 className="title-card">Card</h3>
              <div className="cardinfo-bxx">
                <div className="detail-cardd">
                  <div className="cardpic">
                    <img src={card_bg} alt="" />
                  </div>
                  <div className="cardebit-bxx">
                    <div className="card-content">
                      <div className="name-card">
                        {state?.stripe?.cards[0]?.billing_details?.name}
                      </div>
                      <div className="card-num">
                        XXXX XXXX XXXX {state.stripe.cards[0]?.card.last4}
                      </div>
                    </div>

                    <div className="card-expire">
                      <div className="card-exp-detail">
                        <div className="name-card">Expiry Date</div>
                        <div className="card-num">
                          {state.stripe.cards[0]?.card.exp_month}/
                          {state.stripe.cards[0]?.card.exp_year}
                        </div>
                      </div>

                      <div className="card-debit">
                        <div className="cardtex-right">
                          {state.stripe.cards[0]?.card.funding}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="addnew-card">
                <Link to="/add-card" className="btn-org btn-bxx btn-primaryy">
                  Update Card
                </Link>
              </div>
            </div>
          </div>
        )}
        {state.stripe?.banks && 
          <div className="card-info boxcard-wallet">
            <div className="card-infobx">
              <h3 className="title-card">Bank Detail</h3>
              <div className="cardinfo-bxx">
                <div className="detail-cardd">
                  <div className="cardpic">
                    <img src={card_bg} alt="" />
                  </div>
                  <div className="cardebit-bxx">
                    <div className="card-content">
                      <div className="name-card">
                      {state.stripe.banks[0]?.account_holder_name}
                      </div>
                      <div className="bank-num">
                      XXXX XXXX XXXX {state.stripe.banks[0]?.last4}
                      </div>
                    </div>

                    <div className="card-expire">
                      <div className="card-exp-detail">
                        <div className="name-card">Routing Number</div>
                        <div className="bank-num">
                        {state.stripe.banks[0]?.routing_number}
                        </div>
                      </div>

                      <div className="card-debit">
                        <div className="cardtex-right">
                        {state.stripe.banks[0]?.account_holder_type}
                        </div>
                        <div className="cardtex-right">
                        {state.stripe.banks[0]?.status}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="addnew-card">
                <Link to="/add-bank" className="btn-org btn-bxx btn-primaryy">
                  Add New Bank
                </Link>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default WalledSideBar;
