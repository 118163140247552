import React, { useEffect } from "react";
import "./VerifyBankDetail.css";
import {
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Footer from "../Footer";
import { getAuth } from "../../Routes/Routes";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import { verifyBankDetail, Loading, fetchAllBank } from "../../store/actions/stripeActions";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form'
import { Link} from "react-router-dom";
import { profileToggleAction } from "../../store/actions/toggleAction";

const config = require(`../../enviorment/${process.env.NODE_ENV}.jsx`).config;
const stripePromise = loadStripe(
  config.stipe_key
);


const VerifyBankForm = () => {
  const history = useHistory();
  const stripeState = useSelector((state) => state.stripe);
  let { loading, banks } = stripeState;
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  React.useEffect(() => {
    dispatch(fetchAllBank());
  }, []);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
    bank_id:'',
    first_amount:32,
    second_amount:45,
    },
  })
  watch();
  const onSubmit = async (data, event) => {
    try {
      dispatch(Loading(true));
        if (!stripe || !elements) return false;
        const user = JSON.parse(localStorage.getItem("FXuser"));
        let bankPayload = {
            bank_id:data.bank_id,
            first_amount:data.first_amount,
            second_amount:data.second_amount,
        }
      dispatch(verifyBankDetail(bankPayload)).then((res) => {
          if(res?.payload?.error?.isError) {
            let errorMessage = (res?.payload?.error?.errorMessage) ? res?.payload?.error?.errorMessage : 'Something went wrong during verify bank account.' 
            toast.error(errorMessage)
          } else{
            toast.success("Bank Verified.")
            return history.push("/profile");
          }
          dispatch(Loading(false))
      })
    } catch (err) {
      console.log('\n error in submit:', err.message || err)
    }
  };
  

  const onError = async (errors, event) => {
    try {
      console.log('\n on error:', errors, '\n event:', event?.target) 
    } catch (error) {
      console.log('\n error in onError fun:', error.message || error)
    }
  }
  

  return (
    <>
      <Header auth={getAuth} />
      <div className="wrapper" onClick={()=>dispatch(profileToggleAction(false))}>
        <section className="login-box">
          <div className="container-login-bx card-conatiner">
            <div
              className="section-main-cardd"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              <div className="row">
                <div className="col-md-12">
                  <form>
                    <div className="login-form-new ">
                      <div className="form-logn-bx">
                        <div className="login-detail-frm form-layoutt pt-0">
                          <div className="headg-bx-lgn ">
                            <h4 className="heading-frmm">Verify Bank Account</h4>
                          </div>
                          {/* <div className="form-filedbx"> */}
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                              <select
                                className="form-control"
                                id="bank_id"
                                {...register('bank_id', {
                                  required: 'Please select bank id',
                                })}
                                value={getValues('bank_id')}
                                
                              >
                                <option value="">Select Bank</option>
                                {banks.map((element, index) => (
                                    <option value={element.id} key="bank-{index}">XXXXXXXXX{element.last4}</option>
                                ))}
                              </select>
                              </div>
                              {errors.bank_id && errors.bank_id.message &&  <span className="error">{errors.bank_id.message}</span>}
                            </div>
                            <div className="form-group">
                                <div className="frm-bx-fldn">
                                <input
                                  type="number"
                                  {...register('first_amount', {
                                    required: 'Please enter first_amount',
                                  })}
                                  value={getValues('first_amount')}
                                  className="form-control"
                                  id="first_amount"
                                  placeholder="first_amount"
                                />
                                </div> 
                                {errors.first_amount && errors.first_amount.message && <span className="error">{errors.first_amount.message}</span>}
                            </div>
                            <div className="form-group">
                                <div className="frm-bx-fldn">
                                <input
                                  type="number"
                                  {...register('second_amount', {
                                    required: 'Please enter second_amount',
                                  })}
                                  value={getValues('second_amount')}
                                  className="form-control"
                                  id="second_amount"
                                  placeholder="second_amount"
                                />
                                </div>
                                {errors.second_amount && errors.second_amount.message && <span className="error">{errors.second_amount.message}</span>}
                            </div>
                            
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <button
                                  onClick={handleSubmit(onSubmit, onError)}
                                  type="button"
                                  name="button"
                                  className="btn-logn-frm btn-org"
                                >
                                  {loading ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      Loading...
                                    </>
                                  ) : (
                                    <span className="btn-tex">Verify Bank </span>
                                  )}
                                </button>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                              <Link to="/profile" className="btn-org btn-bxx btn-primaryy">
                                    Back
                                    </Link>
                              </div>
                            </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};

const VerifyBankDetail = () => {
  return (
    <Elements stripe={stripePromise}>
      <VerifyBankForm />
    </Elements>
  );
};
export default VerifyBankDetail;
