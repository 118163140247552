import React from "react";
import iconmonstr_home from "../../images/iconmonstr-phone.svg";
import iconmonstr_email from "../../images/iconmonstr-email.svg";
import iconmonstr_phone from "../../images/iconmonstr-home.svg";
import shape_bg from "../../images/shape-bg.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer-main">
        <div className="shape-btom">
          <img src={shape_bg} alt="" />
        </div>
        <div className="container">
          <div className="footer-box">
            <div className="subscribe">
              <form>
                <div className="subscrib-frm">
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="label-tex">
                        Subscribe to Our Newsletter
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="subsc-right">
                        <div className="form-group">
                          <div className="frm-bx-fldn">
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputEmail"
                              aria-describedby="emailHelp"
                              placeholder="Enter Email"
                              defaultValue=""
                            />
                            <div className="subscrib-btn">
                              <button
                                type="button"
                                name="button"
                                className="btn-subsrib-now"
                              >
                                Subscribe Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="footer-about">
              <div className="footer-bottom">
                <div className="row">
                  <div className="col-md-5 one">
                    <div className="foter-card">
                      <div className="footer-heading">ABOUT FX RUMBLE</div>
                      <div className="footer-content">
                        <p>
                          Fx Rumble is a concept created by the italian trader
                          Marco Tosoni, who turns pro on 2009 and popular in
                          Italy as the biggest Forex Constests winner in the
                          country with performances up to 300% on a quarter
                          base. Thanks to his experience on the financial
                          market, Marco has created Fx Rumble for whoever will
                          to improve his trading skill and wish to earn money
                          and knowledge in a funny and entertaining way.{" "}
                          <Link to="">Read More</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 two">
                    <div className="foter-card">
                      <div className="footer-heading">USEFUL LINKS</div>
                      <div className="footer-list">
                        <ul>
                          <li>
                            <Link to="">
                              <i className="fas fa-chevron-right"></i> About us
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <i className="fas fa-chevron-right"></i>
                              Advertising
                            </Link>
                          </li>

                          <li>
                            <Link to="">
                              <i className="fas fa-chevron-right"></i> Help
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <i className="fas fa-chevron-right"></i> Security
                              of Account Balances
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 three">
                    <div className="foter-card">
                      <div className="footer-heading">ADDRESS</div>
                      <div className="footer-adress">
                        <ul>
                          <li>
                            <span className="icon-add">
                              <img src={iconmonstr_home} />
                            </span>
                            <div className="texx">
                              UFGL UNION FINANCE GROUP LLC
                            </div>
                          </li>
                          <li>
                            <span className="icon-add">
                              <img src={iconmonstr_email} />
                            </span>
                            <a className="texx" href="mailto:info@fxrumble.com">
                              info@fxrumble.com
                            </a>
                          </li>
                          <li>
                            <span className="icon-add">
                              <img src={iconmonstr_phone} />
                            </span>
                            <div className="texx">
                              16192 Coastal Highway, Lewes County of Sussex
                              Delaware (US)
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="foter-card ftr-social-ic">
                      <div className="footer-heading">SOCIAL LINKS</div>
                      <div className="footer-social">
                        <ul>
                          <li>
                            <Link to="">
                              <i className="fab fa-facebook-f"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <i className="fab fa-twitter"></i>
                            </Link>
                          </li>

                          <li>
                            <Link to="">
                              <i className="fab fa-linkedin-in"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="foter-botm-sectionn">
        <div className="container">
          <div className="copywrt">
            <div className="row">
              <div className="col-md-6 one">
                <div className="text">
                  © copyright 2021. All rights reserved
                </div>
              </div>
              <div className="col-md-6 two">
                <div className="right-listing-term">
                  <ul>
                    <li>
                      <Link to=""> Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to=""> Terms of Services</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
