import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import iconmonstr_email from "../../images/iconmonstr-email.svg";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../Header";
import { getAuth } from "../../Routes/Routes";
import Footer from "../Footer";
import ScrollToTop from "../../hooks/ScrollToTop";
import { postRequest } from '../../Routes/axios'
import { profileToggleAction } from "../../store/actions/toggleAction";
import { useDispatch } from "react-redux";
const InviteUsers = () => {
  const history = useHistory();
  let [error, setError] = React.useState(null);
  const [clicked, setClicked] = React.useState(false);
  const location = useLocation();
  const [values, setValues] = React.useState(null);
  const [formValues, setFormValues] = React.useState([{ email: "" }]);
  const dispatch=useDispatch();

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    setError(null);
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };
  const validate = (email) => {
    let input = email;
    let errors = "";
    let isValid = true;

    if (typeof input?.email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input?.email)) {
        isValid = false;
        errors = "Please enter  valid email address.";
      }
    }
    if (!input?.email) {
      isValid = false;
      errors = "Please enter email  Address.";
    }
    let user = JSON.parse(localStorage.getItem("FXuser"));

    if (input?.email === user?.email) {
      isValid = false;
      errors = "You can't enter your email address.";
    }
    setError(errors);
    return isValid;
  };
  let addFormFields = () => {
    let dup = formValues.filter(
      (x) => x.email === formValues[formValues.length - 1].email
    );
    if (dup.length > 1) {
      setError(`Email's can't be same`);
      return false;
    }
    if (!validate(formValues[formValues.length - 1])) return false;
    if (formValues.length === parseInt(values?.participants) - 1) return false;
    setFormValues([...formValues, { email: "" }]);
  };

  let removeFormFields = (i) => {
    setError(null);
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  React.useEffect(() => {
    if (!location.state || !location.state.path === "/create-tournament") {
      return history.push("/", { replace: true });
    }
    if (location.state) {
      setValues(location.state.values);
    }
  }, [location.state]);
  const handleCreate = async () => {
    if (!validate(formValues[formValues.length - 1])) return false;
    if (formValues.length !== parseInt(values?.participants) - 1) {
      setError("Please send invite to all participants");
      return false;
    }
    setClicked(true);
    let res = await postRequest(`/tournament/add`, {
      ...values,
      emails: formValues,
    });
    setClicked(false);
    if (res && res?.success === false) return alert(res.data.toString());

    return history.push("/all-tournaments", { replace: true });
  };
  const handleCancel = () => {
    return history.push("/all-tournaments", { replace: true });
  };
  return (
    <div>
      <Header auth={getAuth} />
      <div className="wrapper" onClick={()=>dispatch(profileToggleAction(false))}>
        <section className="innerpage-bx add-wallet-dashboard tournament-create">
          <div className="club-logo-rxrbl">
            <img src="images/blueclub-logo.png" alt="" />
          </div>
          <div className="container">
            <div className="add-wallet-card">
              <div className="wallet-card-frm">
                <div className="walletcard-frm">
                  <div className="form-addto-wallet">
                    <h4 className="title">Create {values ?.type == "headsUp" ? "Heads Up" : "Club" } Tournament</h4>
                    <div className="addamount-filed form-layoutt">
                      {formValues.map((element, index) => (
                        <div className="form-group" key={index}>
                          <div className="frm-bx-fldn">
                            <div className="fld-left-bx">
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                id="exampleInputEmail"
                                aria-describedby="emailHelp"
                                placeholder="Email"
                                value={element.email}
                                onChange={(e) => handleChange(index, e)}
                              />
                              <span className="icon-form-fld">
                                <img src={iconmonstr_email} alt="" />
                              </span>
                            </div>
                            <div className="btn-box">
                              {formValues.length !== 1 && (
                                <button
                                  className="invite_button"
                                  onClick={() => removeFormFields(index)}
                                >
                                  -
                                </button>
                              )}
                              {formValues.length - 1 === index && (
                                <button
                                  className="invite_button"
                                  onClick={() => addFormFields()}
                                >
                                  +
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {error && <span className="error">{error}</span>}
                    <div className="btn-proced-list">
                      <div className="btn-procced procbtn">
                        <button
                          disabled={clicked}
                          onClick={handleCreate}
                          className="btn-createnw btn-bxx"
                        >
                          {clicked ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Loading...
                            </>
                          ) : (
                            <> Create Tournament</>
                          )}
                        </button>
                      </div>
                      <div className="btn-procced procbtn">
                        <button
                          onClick={handleCancel}
                          className="btn-cancelnw btn-bxx"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default InviteUsers;
