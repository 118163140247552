import { constants } from "../actions/constants";


const initialState = {
    profileToggle:false
}

const profileToggleReducer = (state=initialState, action)=>{
switch (action.type) {
    case constants.PROFILE_TOGGLE:
      return {...state, profileToggle:action.payload}     

    default:
       return initialState
}
}


export {profileToggleReducer}