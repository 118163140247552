import React from "react";
import { Link } from "react-router-dom";

import Header from "../Header";
import { getAuth } from "../../Routes/Routes";
import { profileToggleAction } from "../../store/actions/toggleAction";
import { useDispatch } from "react-redux";

const AddWallet = () => {
  const dispatch=useDispatch();
  return (
    <>
      <Header auth={getAuth} />
      <section className="innerpage-bx add-wallet-dashboard" onClick={()=>dispatch(profileToggleAction(false))}>
        <div className="club-logo-rxrbl">
          <img src="images/blueclub-logo.png" alt="" />
        </div>
        <div className="container">
          <div className="add-wallet-card">
            <div className="wallet-card-frm">
              <div className="walletcard-frm">
                <div className="form-addto-wallet">
                  <h4 className="title">Add FX Coin</h4>
                  <p>
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.
                  </p>
                  <div className="addamount-filed">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputtext1"
                        placeholder="Add Amount"
                        defaultValue=""
                      />
                    </div>
                  </div>
                  <div className="custom-radiobx">
                    <label className="radio-wallet">
                      <input
                        type="radio"
                        defaultChecked="checked"
                        name="radio"
                        defaultValue=""
                      />
                      <span className="checkmark" />
                      <div className="card-detaill">
                        <div className="card-detail-main">
                          <div className="card-left">
                            <div className="label-tex">Dolor sit amet</div>
                            <div className="texx">4521 XXXX XXXX XXXX</div>
                          </div>
                          <div className="card-right">
                            <div className="card-icon">
                              <img src="images/card-icon1.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="custom-radiobx">
                    <label className="radio-wallet">
                      <input type="radio" name="radio" defaultValue="" />
                      <span className="checkmark" />
                      <div className="card-detaill">
                        <div className="card-detail-main">
                          <div className="card-left">
                            <div className="label-tex">Dolor sit amet</div>
                            <div className="texx">4521 XXXX XXXX XXXX</div>
                          </div>
                          <div className="card-right">
                            <div className="card-icon">
                              <img src="images/card-icon2.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="custom-radiobx">
                    <label className="radio-wallet">
                      <input type="radio" name="radio" defaultValue="" />
                      <span className="checkmark" />
                      <div className="card-detaill">
                        <div className="card-detail-main">
                          <div className="card-left">
                            <div className="label-tex">Dolor sit amet</div>
                            <div className="texx">4521 XXXX XXXX XXXX</div>
                          </div>
                          <div className="card-right">
                            <div className="card-icon">
                              <img src="images/card-icon3.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="custom-radiobx">
                    <label className="radio-wallet">
                      <input type="radio" name="radio" defaultValue="" />
                      <span className="checkmark" />
                      <div className="card-detaill">
                        <div className="card-detail-main">
                          <div className="card-left">
                            <div className="label-tex">Dolor sit amet</div>
                            <div className="texx">4521 XXXX XXXX XXXX</div>
                          </div>
                          <div className="card-right">
                            <div className="card-icon">
                              <img src="images/card-icon4.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="btn-proced-list">
                    <div className="btn-procced procbtn">
                      <button className="btn-org btn-bxx btn-primaryy">
                        Proceed
                      </button>
                    </div>
                    <div className="btn-procced newcard">
                      <Link to="" className="loginbtn btn-bxx btn-primaryy">
                        Add New Card
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddWallet;
