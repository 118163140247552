import { constants } from "./constants";


const profileToggleAction = (boolean) => async(dispatch)=>{
    
return dispatch({type:constants.PROFILE_TOGGLE, payload:boolean})
}




export {profileToggleAction}