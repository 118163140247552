import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/blueclub-logo.png";
import smalfx_icon from "../../images/smalfx-icon.svg";
import cardIcon from "../../images/card-icon1.png";
import Header from "../Header";
import { getAuth } from "../../Routes/Routes";
import { useDispatch, useSelector } from "react-redux";
import { profileToggleAction } from "../../store/actions/toggleAction";

const AddWalletSuccessfully = () => {
  const stripe = useSelector((state) => state.stripe);
  const dispatch=useDispatch();
  let { buyPlanData } = stripe;
  return (
    <div>
      <Header auth={getAuth} />
      <div className="wrapper" onClick={()=>dispatch(profileToggleAction(false))}>
        <section className="innerpage-bx add-wallet-dashboard wallet-succesful">
          <div className="club-logo-rxrbl">
            <img src={logo} alt="" />
          </div>
          <div className="container">
            <div className="add-wallet-card">
              <div className="wallet-card-frm-succes">
                <form className="walletcard-frm">
                  <div className="form-addto-wallet">
                    <div className="login-detail-frm form-layoutt succes-wallet-peyment">
                      <div className="tickcrcl">
                        <div className="anim-tick">
                          <svg
                            version="1.1"
                            id="tick"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 37 37"
                            style={{ enableBackground: "new 0 0 37 37" }}
                            xmlSpace="preserve"
                          >
                            <path
                              className="circ path"
                              style={{
                                fill: "none",
                                stroke: "#fff",
                                strokeWidth: "1.5",
                                strokeLinejoin: "round",
                                strokeMiterlimit: 10,
                              }}
                              d="
                        M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
                            />
                            <polyline
                              className="tick path"
                              style={{
                                fill: "none",
                                stroke: "#fff",
                                strokeWidth: "1.5",
                                strokeLinejoin: "round",
                                strokeMiterlimit: 10,
                              }}
                              points="
                        11.6,20 15.9,24.2 26.4,13.8 "
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="headg-bx-lgn forgot-pssw">
                        <h4 className="heading-frmm grey-clr">
                          PAYMENT SUCCESSFUL!
                        </h4>
                        <p className="text">
                          Transaction Number:&nbsp;
                          {buyPlanData?.data?.balance_transaction}
                        </p>
                      </div>
                    </div>
                    <div className="coinadded">
                      <div className="coinadd-card">
                        <div className="coin-inner-left">
                          <div className="labeltex">Coins Added</div>
                          <div className="right-texx">
                            <span className="pic">
                              <img src={smalfx_icon} alt="" />
                            </span>
                            <span className="tex-right">
                              {buyPlanData?.data?.amount}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="coinadd-card">
                        <div className="coin-inner-left">
                          <div className="labeltex">Payment Method</div>
                          <div className="right-texx">
                            <img src={cardIcon} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn-proced-list">
                      <div className="btn-procced procbtn">
                        <Link to="" className="btn-org btn-bxx btn-primaryy">
                          Go To Homepage
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddWalletSuccessfully;
