import React from "react";
import { Route, Redirect } from "react-router-dom";
import jwtDecode from "jwt-decode";

export const ProtectedRoute = ({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !auth() ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
};

export const PublicRoute = ({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth() ? <Redirect to="/profile" /> : <Component {...props} />
      }
    />
  );
};
export const getAuth = () => {
  if (localStorage.getItem("auth-token")) {
    const decodedToken = jwtDecode(localStorage.getItem("auth-token"));
    if (decodedToken.exp * 1000 < Date.now()) {
      localStorage.removeItem("auth-token");
      return false;
    } else {
      return true;
    }
  }
  return false;
};
