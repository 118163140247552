import * as React from 'react';
import './index.css';
import { widget } from '../../charting_library';
import { backendChartUrl } from '../../Routes/axios'
import moment from "moment";

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export class TVChartContainer extends React.PureComponent {
	/*
	symbols: [
			{
			  "proName": "FX_IDC:EURUSD",
			  "title": "EUR/USD"
			}, {
			  "proName": "BITSTAMP:BTCUSD",
			  "title": "BTC/USD"
			}, {
			  "proName": "BITSTAMP:ETHUSD",
			  "title": "ETH/USD"
			}
		  ],
	*/
	static defaultProps = {};

	tvWidget = null;



	constructor(props) {
		try {
			super(props);
			const timezone = 'America/Caracas' //Intl.DateTimeFormat().resolvedOptions().timeZone;
			this.state = {
				symbol: props.type && props.tournamentId  ? `${props.type}-${props.tournamentId}` : 'EURUSD-0',
				interval: '1',
				datafeedUrl: backendChartUrl,
				timezone: timezone,
				//timeframe:{ from: 1667520627, to: 1667350627 }, // we will use later this with dynamic time frame according to event start and end time,
				timeframe:'1',
				debug:true,
				libraryPath: '/charting_library/',
				fullscreen: false,
				autosize: true,
				study_count_limit:5,
				disabled_features: ['left_toolbar', 'use_localstorage_for_settings', 'header_settings', 'header_indicators', 'header_compare', 'header_undo_redo', 'header_fullscreen_button', 'go_to_date'], // "header_widget",
				snapshot_url:`${backendChartUrl}/chart-snapshot`,
				chartsStorageUrl: 'https://saveload.tradingview.com',
				time_frames:[
					{text: "1M", resolution: "1", description: "1 minute"},
					//{text: "20S", resolution: "20S", description: "20 seconds"}
				],
				chartsStorageApiVersion: '1.1',
				clientId: 'tradingview.com',
				userId: 'public_user_id',
				theme:'Dark', // Dark
				startdatetime : props?.startdatetime ?  moment(props?.startdatetime).utc() : moment().utc().subtract('1', 'hours')

			}
			this.ref = React.createRef();
		} catch (e) {
			console.log('\n error in code...', e.message || e);
		}
		
	}

	componentDidMount() {
		const widgetOptions = {
			symbol: this.state.symbol,
			// BEWARE: no trailing slash is expected in feed URL
			datafeed: new window.Datafeeds.UDFCompatibleDatafeed(this.state.datafeedUrl),
			interval: this.state.interval,
			container: this.ref.current,
			timeframe:this.state.timeframe,
			timezone:this.state.timezone,
			debug: this.state.debug,
			library_path: this.state.libraryPath,
			fullscreen: this.state.fullscreen,
			autosize: this.state.autosize,
			study_count_limit:this.state.study_count_limit,	
			disabled_features:this.state.disabled_features,		
			theme:this.state.theme,
			//snapshot_url: this.state.snapshot_url,
			//preset:"mobile",
			time_frames:this.state.time_frames,
			locale: getLanguageFromURL() || 'en',
			//enabled_features: ['study_templates'],
			//charts_storage_url: this.state.chartsStorageUrl,
			//charts_storage_api_version: this.state.chartsStorageApiVersion,
			client_id: this.state.clientId,
			user_id: this.state.userId,
		};

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		tvWidget.onChartReady(() => {
			/* tvWidget.headerReady().then(() => {
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
					title: 'Notification',
					body: 'TradingView Charting Library API works correctly',
					callback: () => {
						console.log('Noticed!');
					},
				}));

				button.innerHTML = 'Check API';
			}); */
			
			tvWidget.subscribe('onTick', (data)=> {
				try {
					//console.log('\n onTick data:', data)
					if(this.props.setCoinData) {
						this.props.setCoinData(data)
					}
				} catch (err) {
					console.log('\n onTick err:', err.message || err)
				}
			})
			tvWidget.activeChart().onDataLoaded().subscribe(
				null,
				() => {
					//console.log('New history bars are loaded')
					if(!this.props.initialLoad) {
						this.props.setInitialLoad(true)
					}
				},
				true
			);

			tvWidget.activeChart().setChartType(1)
			const startTime = moment(this.state.startdatetime).utc().unix()
			const endTime = moment(this.state.startdatetime).utc().add('4', 'hours').unix()
			//console.log('\n startTime:', startTime, 'endTime:', endTime)
			tvWidget.activeChart().setScrollEnabled(false);
			tvWidget.activeChart().setVisibleRange(
				{ from: startTime, to: endTime },
				{ percentRightMargin: 5 }
			).then(() => {
				console.log('New visible range is applied')
			}); 

		});
		
	}

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	render() {
		return (
			<div
				ref={ this.ref }
				className={ 'TVChartContainer' }
			/>
		);
	}
}
