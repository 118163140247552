import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Header from "../Header";
import pencil from "../../images/pencil.svg";
import { getAuth } from "../../Routes/Routes";
import DateTimePicker from "react-datepicker";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "../Footer";
import ScrollToTop from "../../hooks/ScrollToTop";
import { profileToggleAction } from "../../store/actions/toggleAction";
import { useDispatch } from "react-redux";
const config = require(`../../enviorment/${process.env.NODE_ENV}.jsx`).config;
const CreateTournament = (props) => {
  const [startDate, setStartDate] = React.useState(null);
  const history = useHistory();
  const location = useLocation();
  const userDetails = localStorage.getItem("userFullName");
  const dispatch=useDispatch();
  const [values, setValues] = React.useState({
    duration: "",
    participants: "2",
    fees: "",
    date: "",
    tradableMartket: "",
    type: props.match.params.type,
    privacyType: "",
    createdBy: userDetails
  });
  const [error, setError] = React.useState({
    duration: null,
    participants: null,
    date: null,
    tradableMartket: null,
    fees: null,
    privacyType: null,
  });
  useEffect(() => {
    if (location.state) {
      setValues(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const addHours = ({ date, h }) => {
    let time = date.getTime() + h * 60 * 60 * 1000;
    return time;
  };
  const addMinutes = ({ date, m }) => {
    let time = date.getTime() + m * 60 * 1000;
    return time;
  };
  const handleCreate = () => {
    setError({
      ...error,
      date:
        values.date === "" || values.date === null ? "Please enter date" : null,
      duration: values.duration === "" ? "Please select duration" : null,
      participants:
        values.participants === "" ? "Please select participants" : null,
      tradableMartket:
        values.tradableMartket === "" ? "Please select tradeable market" : null,
      fees: values.fees === "" ? "Please enter tournament fees" : null,
      privacyType:
        values?.privacyType === "" ? "Please enter privacy type" : null,
    });

    if (Object.values(values).filter((x) => x === "").length > 0) return false;
    let endTime;
    let newDate = new Date(values.date);
    if (values.duration.split(" ")[1] === "min") {
      let added = addMinutes({ date: newDate, m: parseInt(values.duration) });
      endTime = added;
    }
    if (values.duration.split(" ")[1] === "h") {
      let added = addHours({ date: newDate, h: parseInt(values.duration) });
      endTime = added;
    }

    endTime = moment.utc(endTime).format();

    return history.push({
      pathname: "/invite-users",
      state: {
        values: { ...values, endTime: endTime, date: startDate },
        path: "/create-tournament",
      },
    });
  };
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const handleCancel = () => {
    return history.push("/all-tournaments");
  };
  
  return (
    <div>
      <Header auth={getAuth} />
      <div className="wrapper" onClick={()=>dispatch(profileToggleAction(false))}>
        <section className="innerpage-bx add-wallet-dashboard tournament-create">
          <div className="club-logo-rxrbl">
            <img src="images/blueclub-logo.png" alt="" />
          </div>
          <div className="container">
            <div className="add-wallet-card">
              <div className="wallet-card-frm">
                <div className="walletcard-frm">
                  <div className="form-addto-wallet">
                    <h4 className="title">{values.type == 'headsUp' ? "Heads Up" :"Club"} Tournament</h4>
                    <div className="addamount-filed form-layoutt">
                      <div className="form-group">
                        <div className="frm-bx-fldn">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleTournamentFees"
                            placeholder="Tournament Fees"
                            value={values.fees}
                            onChange={(e) => {
                              setError({
                                ...error,
                                fees: null,
                              });
                              setValues({
                                ...values,
                                fees: e.target.value,
                              });
                            }}
                          />
                          <span className="icon-form-fld">
                            <img src={pencil} alt="" />
                          </span>
                        </div>
                        {error.fees && (
                          <span className="error">{error.fees}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="frm-bx-fldn">
                          <select
                            className="form-control"
                            id="exampleSelectTournamentDuration"
                            value={values.duration}
                            onChange={(e) => {
                              setError({
                                ...error,
                                duration: null,
                              });
                              setValues({
                                ...values,
                                duration: e.target.value,
                              });
                            }}
                          >
                            <option value="">Select Tournament Duration</option>
                            <option value="15 min">15 minutes</option>
                            <option value="30 min">30 minutes</option>
                            <option value="1 h">1 hour</option>
                            <option value="2 h">2 hour</option>
                            <option value="4 h">4 hour</option>
                          </select>
                        </div>
                        {error.duration && (
                          <span className="error">{error.duration}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="frm-bx-fldn">
                          <select
                            className="form-control"
                            id="exampleSelectTournamentParticipants"
                            value={values.participants}
                            onChange={(e) => {
                              setError({
                                ...error,
                                participants: null,
                              });
                              setValues({
                                ...values,
                                participants: e.target.value,
                              });
                            }}
                          >
                            <option value="">
                                Select Tournament Participants
                            </option>
                            { values.type == 'headsUp' ? 
                              (<option value="2" selected>2</option>) : 
                              ( <>
                                <option value="3">3</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                              </>)
                            }
                          </select>
                        </div>
                        {error.participants && (
                          <span className="error">{error.participants}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="frm-bx-fldn">
                          <DateTimePicker
                            locale="pt-BR"
                            showTimeSelect
                            timeFormat="p"
                            timeIntervals={1}
                            dateFormat="Pp"
                            filterTime={filterPassedTime}
                            minDate={moment().toDate()}
                            selected={startDate}
                            className="form-control"
                            onChange={(date) => {
                              setError({
                                ...error,
                                date: null,
                              });
                              setValues({
                                ...values,
                                date: date.toString(),
                              });
                              setStartDate(date);
                            }}
                            placeholderText="Please enter date and time"
                          />
                        </div>
                        {error.date && (
                          <span className="error">{error.date}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="frm-bx-fldn">
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setError({
                                ...error,
                                tradableMartket: null,
                              });
                              setValues({
                                ...values,
                                tradableMartket: e.target.value,
                              });
                            }}
                            value={values.tradableMartket}
                            id="exampleSelectTradeableMarket"
                          >
                            <option value="">Select Tradeable Market</option>
                            <option value="USDCAD">USD-CAD</option>
                            <option value="EURUSD">EUR-USD</option>
                          </select>
                        </div>
                        {error.tradableMartket && (
                          <span className="error">{error.tradableMartket}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="frm-bx-fldn">
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setError({
                                ...error,
                                privacyType: null,
                              });
                              setValues({
                                ...values,
                                privacyType: e.target.value,
                              });
                            }}
                            value={values.privacyType}
                            id="exampleSelectTournamentType"
                          >
                            <option value="">Select Tournament Type</option>
                            <option value="public">Public</option>
                            <option value="private">Private</option>
                          </select>
                        </div>
                        {error.privacyType && (
                          <span className="error">{error.privacyType}</span>
                        )}
                      </div>
                    </div>

                    <div className="btn-proced-list">
                      <div className="btn-procced procbtn">
                        <button
                          onClick={handleCreate}
                          className="btn-createnw btn-bxx"
                        >
                          Invite
                        </button>
                      </div>
                      <div className="btn-procced procbtn">
                        <button
                          onClick={handleCancel}
                          className="btn-cancelnw btn-bxx"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default CreateTournament;
