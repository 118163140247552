import React from "react";
import { getAuth } from "../../Routes/Routes";
import Footer from "../Footer";
import Header from "../Header";
import profile from "../../images/dummy.png";
import blurBg from "../../images/sahpe-bg-blue.svg";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { buyPlan, Loading } from "../../store/actions/stripeActions";
import WalledSideBar from "../WalledSideBar";
import AddCoinPopUp from "../AddCoinPopUp";
import ScrollToTop from "../../hooks/ScrollToTop";
import { postRequest, postRequestWithErrorHandling } from '../../Routes/axios'
import { profileToggleAction } from "../../store/actions/toggleAction";

const EditProfile = () => {
  const state = useSelector((state) => state);
  const [modal, setModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const { about, email, fullName, fxCoin, id, image } = state?.user?.user;
  let { loading, buyPlanData, cards } = state?.stripe;
  const history = useHistory();
  const dispatch = useDispatch();
  const [coin, setCoin] = React.useState('');
  const [pic, setPic] = React.useState(null);
  const [inputValues, setInputValues] = React.useState({
    fullName: "",
    email: "",
    about: "",
  });
  const [error, setError] = React.useState({
    fullName: "",
    email: "",
    about: "",
  });
  const [preview, setPreview] = React.useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!validate()) return false;
      dispatch(Loading(true));
      const formData = new FormData();
      formData.append("image", pic);
      formData.append("about", inputValues.about);
      formData.append("name", inputValues.fullName);
      formData.append("email", inputValues.email);
      formData.append("fileName", pic?.name);
      let payload = {
        about: inputValues.about,
        name: inputValues.fullName,
        email: inputValues.email,
      };

      const finalPayload = pic ? formData : payload
      let result = await postRequest(`/edit-profile`, finalPayload);
      dispatch(Loading(false));
      if (result?.data?.success === true) return history.push("/profile");
    } catch (err) {
      console.log('\n error on submit:', err.message || err)
    }

  };

  React.useEffect(() => {
    if (!pic) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(pic);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [pic]);

  React.useEffect(async () => {
    setInputValues({
      ...inputValues,
      about: about,
      email: email,
      fullName: fullName,
    });

    setPreview(image);
  }, [email]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setPic(undefined);
      return;
    }
    setPic(e.target.files[0]);
  };
  const validate = () => {
    let input = inputValues;
    let errors = {};
    let isValid = true;
    if (!input["fullName"]) {
      isValid = false;
      errors["fullName"] = "Please enter your fullName.";
    }
    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email address.";
    }
    if (!input["about"]) {
      isValid = false;
      errors["about"] = "Please enter your about.";
    }
    setError({
      ...errors,
    });
    return isValid;
  };
  const addCoins = async () => {
    try {
      dispatch(Loading(true));
      let res = await postRequestWithErrorHandling(`/stripe/buy`, {
        price: parseInt(coin),
      });
      if (res && res.data) {
        dispatch(buyPlan(res.data));
        return history.push("/successfully");
      }
    } catch (error) {
      setErrorMsg(error.message || 'Something went wrong during add amount')
      dispatch(Loading(false));
      console.log('\n error in submit:', error)
    }
  };
  return (
    <>
      <Header auth={getAuth} />

      <div className="wrapper" onClick={() => dispatch(profileToggleAction(false))}>
        <section className="profile-slider edit-pro-top">
          <div className="container">
            <div className="profile-mainbx">
              <div className="custom-row">
                <div className="profile-left">
                  <div className="prifle-detail">
                    <div className="profile-img">
                      <div className="pic-proflnew">
                        {<img src={preview ?? profile} alt="" />}
                      </div>

                      <div className="profile-detal-content">
                        <div className="up-image">
                          <label
                            for="file-upload"
                            className="custom-file-upload"
                          >
                            <i className="fas fa-upload"></i> browse Image
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            name="image"
                            onChange={onSelectFile}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-btom">
            <img src={blurBg} alt="" />
          </div>
        </section>

        <section className="profile-about edit-pro-form">
          <div className="container">
            <div className="profile-abt-boxx">
              <div className="row">
                <div className="col-md-7 col-lg-8 one">
                  <div className="left-detail-bxx">
                    <div className="profile-detal-content">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="email-detail">
                            <label>Name</label>
                            <input
                              type="text"
                              name="fullName"
                              onChange={handleChange}
                              value={inputValues.fullName}
                              placeholder="Enter Name"
                              className="ed-fld"
                            />
                          </div>

                          {error.fullName && (
                            <span className="error">{error.fullName}</span>
                          )}
                        </div>

                        <div className="col-lg-6">
                          <div className="email-detail">
                            <label>Email</label>
                            <input
                              type="text"
                              name="email"
                              onChange={handleChange}
                              value={inputValues.email}
                              placeholder="Enter Email"
                              className="ed-fld" readOnly
                            />
                          </div>
                          {error.email && (
                            <span className="error">{error.email}</span>
                          )}
                        </div>

                        <div className="col-lg-12">
                          <div className="about-dtl">
                            <label>About me</label>
                            <textarea
                              maxLength="200"
                              placeholder="Description"
                              value={inputValues.about}
                              onChange={handleChange}
                              name="about"
                              className="desc-box"
                            ></textarea>

                            {error.about && (
                              <span className="error">{error.about}</span>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="profile-edit-right">
                            <div className="btn-editt">
                              <button
                                onClick={handleSubmit}
                                className="btn-org btn-bxx-smll"
                                disabled={loading}
                              >
                                {loading ? (
                                  <>
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    Loading...
                                  </>
                                ) : (
                                  <>
                                    <span className="icon-btnn">
                                      <i className="far fa-save"></i>
                                    </span>
                                    <span className="btn-tex">Save</span>
                                  </>
                                )}
                              </button>
                              <a href="" className="btn-cancel btn-bxx-smll">
                                <span className="icon-btnn">
                                  <i className="fas fa-times"></i>
                                </span>
                                <span className="btn-tex">Cancel</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <WalledSideBar
                  state={state}
                  cards={cards}
                  fxCoin={fxCoin}
                  setModal={(val) => setModal(val)}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <AddCoinPopUp
        handleSubmit={() => addCoins()}
        loading={loading}
        modal={modal}
        coin={coin}
        setCoin={(val) => setCoin(val)}
        setModal={(val) => setModal(val)}
        errorMsg={errorMsg}
        setErrorMsg={(val) => setErrorMsg(val)}
      />
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default EditProfile;
