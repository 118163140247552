const initialState = {
  user: {
    about: null,
    email: null,
    fullName: null,
    fxCoin: null,
    id: null,
    image: null,
  },
};

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_USER":
      return { ...state, user: payload };

    default:
      return state;
  }
};
