import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import fx_rumble_schl from "../../images/fx-rumble-schl.png";
import shape_bg_blue from "../../images/sahpe-bg-blue.svg";
import side_bg from "../../images/side-bg.png";
import poker1 from "../../images/poker1.jpg";
import poker2 from "../../images/poker2.jpg";
import poker3 from "../../images/poker3.jpg";
import article1 from "../../images/article1.jpg";
import article2 from "../../images/article2.jpg";
import article3 from "../../images/article3.jpg";
import article4 from "../../images/article4.jpg";
import video_tut1 from "../../images/video-tut1.jpg";
import video_tut2 from "../../images/video-tut2.jpg";
import video_tut3 from "../../images/video-tut3.jpg";
import video_tut4 from "../../images/video-tut4.jpg";
import video_tut5 from "../../images/video-tut5.jpg";
import video_tut6 from "../../images/video-tut6.jpg";
import Header from "../Header";
import Footer from "../Footer";
import { getAuth } from "../../Routes/Routes";
import ScrollToTop from "../../hooks/ScrollToTop";
import { profileToggleAction } from "../../store/actions/toggleAction";
import { useDispatch } from "react-redux";

const School = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, []);
  const dispatch = useDispatch();
  return (
    <div>
      <Header auth={getAuth} />
      {/* ===== slider section ========*/}
      <section
        className="slider-inner-main"
        onClick={() => dispatch(profileToggleAction(false))}
      >
        <div className="custom-container">
          <div className="inner-slidrbx">
            <div className="row align-items-center">
              <div className="col-md-6 one">
                <div className="left-texsldr">
                  <div className="subtitle-bx">
                    <span className="smal-line" />
                    <span className="subtitle">FX RUMBLE SCHOOL</span>
                  </div>
                  <div className="heading-topp">
                    <h1 className="title-large">LEARN & EARN WITH FX RUMBLE</h1>
                    <p className="medium-text box-space">
                      Welcome to our trading school, here you can find articles
                      and videos about the basics of trading, strategies, ideas,
                      techniques to play and special courses with professional
                      traders and the best players of Fx Rumble.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 two">
                <div className="right-pic-sldr">
                  <img src={fx_rumble_schl} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-btom">
          <img src={shape_bg_blue} alt="" />
        </div>
      </section>
      {/* ===== slider section end========*/}
      <section className="play-pooker">
        <div className="custom-container">
          <div className="pooker-box">
            <div className="heading-main">
              <h4>
                <span className="title-medium white-br-botm">
                  ENTER THE WORLD OF TRADING
                </span>
              </h4>
              <div className="content-bxx-subtitle">
                <p>
                  We believe that gamification of trading is the key to succeed,
                  but first thing first, explore the Fx Rumble School and get
                  some extra knowledge!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="pooker-play-cards">
          <div className="right-bg-pix">
            <img src={side_bg} alt="" />
          </div>
          <div className="container">
            <div className="pooker-play-cardbx">
              <div className="row">
                <div className="col-sm-6 col-md-4 one">
                  <div className="poker-card-body">
                    <div className="pokker-pic-bx poker-picc">
                      <img src={poker1} alt="" />
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">Dow Theory Explained</h5>
                      <div className="learn-now">
                        <Link to="" className="btn-org btn-bxx-smll">
                          Learn Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 one">
                  <div className="poker-card-body">
                    <div className="pokker-pic-bx poker-picc">
                      <img src={poker2} alt="" />
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        Introduction to Technical Analysis
                      </h5>
                      <div className="learn-now">
                        <Link to="" className="btn-org btn-bxx-smll">
                          Learn Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 one">
                  <div className="poker-card-body">
                    <div className="pokker-pic-bx poker-picc">
                      <img src={poker3} alt="" />
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        Introduction to Algorithmic Trading
                      </h5>
                      <div className="learn-now">
                        <Link to="" className="btn-org btn-bxx-smll">
                          Learn Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="articles-main">
        <div className="custom-container">
          <div className="articles-bx">
            <div className="heading-main">
              <h4>
                <span className="title-medium white-br-botm">Articles</span>
              </h4>
            </div>
            <div className="articles-cards">
              <div className="row">
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 one">
                  <Link to="" className="listartcl">
                    <div className="poker-card-body">
                      <div className="pokker-pic-bx atricle-piccbx">
                        <img src={article1} alt="" />
                      </div>
                      <div className="pokker-content">
                        <h5 className="card-title">
                          Learn how trading chart works with pros and cons
                        </h5>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 two">
                  <Link to="" className="listartcl">
                    <div className="poker-card-body">
                      <div className="pokker-pic-bx atricle-piccbx">
                        <img src={article2} alt="" />
                      </div>
                      <div className="pokker-content">
                        <h5 className="card-title">
                          How to make forecasts with the prize patterns
                        </h5>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 three">
                  <Link to="" className="listartcl">
                    <div className="poker-card-body">
                      <div className="pokker-pic-bx atricle-piccbx">
                        <img src={article3} alt="" />
                      </div>
                      <div className="pokker-content">
                        <h5 className="card-title">
                          Money Management explained: the key to succeed
                        </h5>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 four">
                  <Link to="" className="listartcl">
                    <div className="poker-card-body">
                      <div className="pokker-pic-bx atricle-piccbx">
                        <img src={article4} alt="" />
                      </div>
                      <div className="pokker-content">
                        <h5 className="card-title">
                          Indicators and Oscillators: how it works and which
                          ones to choose
                        </h5>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 one">
                  <Link to="" className="listartcl">
                    <div className="poker-card-body">
                      <div className="pokker-pic-bx atricle-piccbx">
                        <img src={article3} alt="" />
                      </div>
                      <div className="pokker-content">
                        <h5 className="card-title">
                          Risk Management Explained: leverage and margin
                          trading. Handle with care!
                        </h5>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 two">
                  <Link to="" className="listartcl">
                    <div className="poker-card-body">
                      <div className="pokker-pic-bx atricle-piccbx">
                        <img src={article4} alt="" />
                      </div>
                      <div className="pokker-content">
                        <h5 className="card-title">
                          Trading with “Naked Price Action”: detecting trend and
                          reversal.
                        </h5>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 three">
                  <Link to="" className="listartcl">
                    <div className="poker-card-body">
                      <div className="pokker-pic-bx atricle-piccbx">
                        <img src={article1} alt="" />
                      </div>
                      <div className="pokker-content">
                        <h5 className="card-title">
                          Divergence trading with Indicators and Oscillators.
                        </h5>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 four">
                  <Link to="" className="listartcl">
                    <div className="poker-card-body">
                      <div className="pokker-pic-bx atricle-piccbx">
                        <img src={article2} alt="" />
                      </div>
                      <div className="pokker-content">
                        <h5 className="card-title">
                          Trading Strategies: Trend Following VS Mean Reverting
                        </h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="video-tutorials-bx">
            <div className="heading-main">
              <h4>
                <span className="title-medium white-br-botm">
                  VIDEO LESSONS & WEBINARS
                </span>
              </h4>
            </div>
            <div className="content-bxx-subtitle">
              <p>
                Here’s some video we selected for you. Get some extra insights
                about trading and strategies.
              </p>
            </div>
            <div className="articles-cards video-tutorials-cards">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-4 one">
                  <div className="videotut-card-body">
                    <div className="pokker-pic-bx vdotut-piccbx">
                      <img src={video_tut1} alt="" />
                      <div className="playicon-bxx">
                        <i className="fas fa-play" />
                      </div>
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        Setting the Trading Dashboard
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 two">
                  <div className="videotut-card-body">
                    <div className="pokker-pic-bx vdotut-piccbx">
                      <img src={video_tut2} alt="" />
                      <div className="playicon-bxx">
                        <i className="fas fa-play" />
                      </div>
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        Enter a tournament or create your own
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 three">
                  <div className="videotut-card-body">
                    <div className="pokker-pic-bx vdotut-piccbx">
                      <img src={video_tut3} alt="" />
                      <div className="playicon-bxx">
                        <span className="icn-playbx">
                          <i className="fas fa-play" />
                        </span>
                      </div>
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        How to analyze your statistics
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 four">
                  <div className="videotut-card-body">
                    <div className="pokker-pic-bx vdotut-piccbx">
                      <img src={video_tut4} alt="" />
                      <div className="playicon-bxx">
                        <i className="fas fa-play" />
                      </div>
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        Watch a tournament simulation
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 four">
                  <div className="videotut-card-body">
                    <div className="pokker-pic-bx vdotut-piccbx">
                      <img src={video_tut5} alt="" />
                      <div className="playicon-bxx">
                        <i className="fas fa-play" />
                      </div>
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">How the tournaments work</h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 four">
                  <div className="videotut-card-body">
                    <div className="pokker-pic-bx vdotut-piccbx">
                      <img src={video_tut6} alt="" />
                      <div className="playicon-bxx">
                        <i className="fas fa-play" />
                      </div>
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        How to register, deposit, withdrawal
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default School;
