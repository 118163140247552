import axios from "axios";

const config = require(`../enviorment/${process.env.NODE_ENV}.jsx`).config;
axios.defaults.baseURL = `${config.backEnd}`

export const backendChartUrl =  config.backEnd || 'https://demo_feed.tradingview.com';

const fetchClient = () => {
  axios.interceptors.request.use(function (config) {
    const token = JSON.parse(localStorage.getItem("auth-token"));
    if (localStorage.getItem("FXuser")) {
      let user = JSON.parse(localStorage.getItem("FXuser"));
      config.headers.email = user?.email ? user?.email : "";
    }
    config.headers.Authorization = token ? `Bearer ${token}` : "";

    return config;
  });
};

export const fetchEndpointData = (endpoint=null) => {
  return new Promise(async (resolve,reject)=> {
    try {
      if(!endpoint) {
        alert('Please provide endpoint for fetch data.')
        return
      }
      const token = JSON.parse(localStorage.getItem("auth-token"));
      if(token) {
        axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : "";
      }
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
      if (localStorage.getItem("FXuser")) {
        let user = JSON.parse(localStorage.getItem("FXuser"));
        axios.defaults.headers.common['email']= user?.email ? user?.email : "";
      }
      const response = await axios.get(endpoint);
      return resolve(response)
    } catch (err) {
      return reject(err)
    }
  })
}
export const postRequest = (endpoint=null, payload=null) => {
  return new Promise(async (resolve,reject)=> {
    try {
      if(!endpoint || !payload) {
        alert('Please provide endpoint and payload  for post request.')
        return
      }
      const token = JSON.parse(localStorage.getItem("auth-token"));
      if(token) {
        axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : "";
      }
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
      if (localStorage.getItem("FXuser")) {
        let user = JSON.parse(localStorage.getItem("FXuser"));
        axios.defaults.headers.common['email']= user?.email ? user?.email : "";
      }
      const response = await axios.post(endpoint, payload);
      return resolve(response)
    } catch (err) {
      return reject(err)
    }
  })
}

export const postRequestWithErrorHandling = (endpoint=null, payload=null) => {
  return new Promise(async (resolve,reject)=> {
    try {
      if(!endpoint || !payload) {
        alert('Please provide endpoint and payload  for post request.')
        return
      }
      const token = JSON.parse(localStorage.getItem("auth-token"));
      if(token) {
        axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : "";
      }
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
      if (localStorage.getItem("FXuser")) {
        let user = JSON.parse(localStorage.getItem("FXuser"));
        axios.defaults.headers.common['email']= user?.email ? user?.email : "";
      }
      const response = await axios.post(endpoint, payload);
      return resolve(response)
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        if(err.response.data.error.code && err.response.data.error.code == 'card_declined') {
          return reject({message:'Your card is declined.Please check your card or try with another card.'})
        } else {
          return reject({message:err.response.data.error.message || 'Something went wrong during update data'})
        }
      } else {
        return reject({message:'Something went wrong during update data'})
      }
    }
  })
}

export default fetchClient;
