import React, { useEffect } from "react";
import shape_bg from "../../images/shape-bg.svg";
import heads_up from "../../images/heads-up.svg";
import Rumble_Satellite from "../../images/Rumble-Satellite.svg";
import shield from "../../images/shield.svg";
import cash_withdrawl from "../../images/cash-withdrawal.svg";
import deposit from "../../images/deposit.svg";
import chess_piece from "../../images/chess-piece.svg";
import Fx_Rumble_Header from "../../images/Fx-Rumble-Header.mov";
import rumble from "../../images/rumble.svg";
import right_arrow from "../../images/right-arrow.svg";
import console1 from "../../images/console.svg";
import club from "../../images/club.svg";
import dice from "../../images/dice.svg";
import video_pic from "../../images/video-pic.jpg";
import mapbg from "../../images/mapbg.png";
import chess_piece_pnk from "../../images/chess-piece-pnk.svg";
import pic_act from "../../images/pic-act.svg";
import lines from "../../images/lines.png";
import stamp from "../../images/stamp.svg";
import { Link } from "react-router-dom";
import Footer from "../Footer/index";
import Header from "../Header/index";
import { getAuth } from "../../Routes/Routes";
import ScrollToTop from "../../hooks/ScrollToTop";
import LoginForm from "../LoginForm";
import SignUpForm from "../SignUpForm";
import * as Constant from "../Constant/index";
import { fetchEndpointData } from "../../Routes/axios";
import KycIcon from "../../images/kyc_icon.svg";
import { useDispatch } from "react-redux";
import { profileToggleAction } from "../../store/actions/toggleAction";

let countries = Constant.COUNTRY_LIST;

const Home = (props) => {
  const [state, setState] = React.useState(true);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const [message, setMessage] = React.useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    caches.delete("rl_session");
    localStorage.removeItem("accessToken");
    const checkstatus = async () => {
      const user = JSON.parse(localStorage.getItem("FXuser"));
      if (user) {
        const { data } = await fetchEndpointData(
          `/kyc/applicants/status?userId=${user.applicantId}`
        );
        if (
          data?.response?.reviewStatus == "completed" &&
          user.applicantId !== null
        ) {
          setOpen(false);
        } else {
          setOpen(true);
        }

        if (user.applicantId == null) {
          setOpen(false);
        }
        if (data?.response?.reviewStatus) {
          if (data?.response?.reviewStatus == "init") {
            setMessage(
              "First, complete your Verify Me application.<br> <small>You  can access the tournament.</small>"
            );
          }
          if (data?.response?.reviewStatus == "pending") {
            setMessage(
              "The approval of your KYC application is pending.<br> <small>You can't access the tournament.</small>"
            );
          }
        }
      }
    };
    checkstatus();
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  return (
    <>
      <Header auth={getAuth} />

      <div
        className="wrapper"
        onClick={() => dispatch(profileToggleAction(false))}
      >
        <section className="slider-mainn">
          <div className="slider-box-containn">
            <div className="myvdieoo">
              <video id="myVideo" autoPlay loop muted>
                <source src={Fx_Rumble_Header} type="video/mp4" />
              </video>
            </div>

            <div className="custom-container">
              <div className="slider-boxx">
                <div className="slider-card">
                  <div className="card-innerbody">
                    <div className="subtitle-bx">
                      <span className="smal-line"></span>
                      <span className="subtitle">WELCOME</span>
                    </div>
                    <h3 className="medium-subtitle">FX RUMBLE</h3>
                    <h1 className="title-large">THE ULTIMATE TRADING GAME</h1>
                    <h3 className="medium-subtitle">No playing no gain</h3>
                    <p className="medium-text box-space">
                      Play against other traders in heads up match, elimination
                      games or create your own tournament and team.
                      <br />
                      Gamification will easily drive you to master daytrading
                      strategies on forex and cryptocurrencies alike.
                      <br />
                      Learning how to make money with Fx Rumble will be easy as
                      pie.
                    </p>
                    <ul className="play-listing-btnn">
                      <li>
                        <div className="play-btnbx">
                          <Link to="/all-tournaments" className="btn-play">
                            Play Now
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="how-works">
                          <Link to="#how-work" className="works-btn">
                            How does it works?
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-btom">
            <img src={shape_bg} alt="" />
          </div>
        </section>

        <section className="rumble-markets">
          <div className="custom-container">
            <div className="rumble-market-box">
              <div className="heading-main">
                <h4>
                  <span className="title-medium white-br-botm">
                    INTRODUCING FX RUMBLE
                  </span>
                </h4>
              </div>
              <div className="market-cards">
                <div className="row">
                  <div className="col-md-6 col-lg-3 one">
                    <div className="market-cardbody">
                      <div className="market-cardinner">
                        <div className="iconbx iconsky">
                          <img src={chess_piece} alt="" />
                        </div>
                        <div className="content-box">
                          <h5 className="meduim-content">Non dealing Desk</h5>
                          <p>
                            Fx Rumble is a platform where you can challenge
                            other traders in heads up match or tournaments with
                            an unlimited pool of players.
                          </p>
                          <div className="redmore-bx">
                            <Link to="" className="readmore-btn">
                              <span className="texx-btn">READ MORE</span>
                              <span className="icon-right">
                                <img src={right_arrow} alt="" />
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 two">
                    <div className="market-cardbody">
                      <div className="market-cardinner">
                        <div className="iconbx iconred">
                          <img src={console1} alt="" />
                        </div>
                        <div className="content-box">
                          <h5 className="meduim-content">Learn {"&"} Earn</h5>
                          <p>
                            Fx Rumble is a trading game where you can play
                            either in real money or for fun in demo mode. FX
                            Rumble AI allows you to improve steadily.
                          </p>
                          <div className="redmore-bx">
                            <Link to="" className="readmore-btn">
                              <span className="texx-btn">READ MORE</span>
                              <span className="icon-right">
                                <img src={right_arrow} alt="" />
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 three">
                    <div className="market-cardbody">
                      <div className="market-cardinner">
                        <div className="iconbx iconyellow">
                          <img src={dice} alt="" />
                        </div>
                        <div className="content-box">
                          <h5 className="meduim-content">Fx Rumble School</h5>
                          <p>
                            Increase your knowledge and expertise with exclusive
                            content produced by the Fx Rumble School of Trading.
                          </p>
                          <div className="redmore-bx">
                            <Link to="" className="readmore-btn">
                              <span className="texx-btn">READ MORE</span>
                              <span className="icon-right">
                                <img src={right_arrow} alt="" />
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 four">
                    <div className="market-cardbody">
                      <div className="market-cardinner">
                        <div className="iconbx iconpink">
                          <img src={chess_piece_pnk} alt="" />
                        </div>
                        <div className="content-box">
                          <h5 className="meduim-content">Build your Team</h5>
                          <p>
                            On Fx Rumble you can create your own team and join
                            team tournaments. Use our chat system to interact
                            and share trading ideas with your friends.
                          </p>
                          <div className="redmore-bx">
                            <Link to="" className="readmore-btn">
                              <span className="texx-btn">READ MORE</span>
                              <span className="icon-right">
                                <img src={right_arrow} alt="" />
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="how-work" id="how-work">
          <div className="custom-container">
            <div className="work-mainbx">
              <div className="heading-main">
                <h4>
                  <span className="title-medium white-br-botm">
                    How it Works
                  </span>
                </h4>
              </div>
              <div className="video-bxx">
                <div className="vido-imgbx">
                  <img src={video_pic} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="safty-security-bx">
          <div className="custom-container">
            <div className="map-pic">
              <img src={mapbg} alt="" />
            </div>
            <div className="cardbox-safty">
              <div className="heading-main">
                <h4>
                  <span className="title-medium white-br-botm">
                    Safety and Security
                  </span>
                </h4>
              </div>
              <div className="safty-cardbox-inner">
                <div className="row">
                  <div className="col-md-6 col-lg-3 one">
                    <div className="safty-cardbody card-one">
                      <div className="cardbox-iconn">
                        <div className="icon-bxcard">
                          <img src={cash_withdrawl} alt="" />
                        </div>
                        <div className="content-box-safty-secr">
                          <h5 className="medium-text-card">
                            Quick Withdrawals
                          </h5>
                          <p>
                            We guarantee all your withdrawals within 5 working
                            days, with 1 free withdrawal per month.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 two">
                    <div className="safty-cardbody card-two">
                      <div className="cardbox-iconn">
                        <div className="icon-bxcard">
                          <img src={shield} alt="" />
                        </div>
                        <div className="content-box-safty-secr">
                          <h5 className="medium-text-card">
                            Player Protection
                          </h5>
                          <p>
                            Fx Rumble is a Non Dealing Desk platform. All our
                            players has the same chance to win.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 three">
                    <div className="safty-cardbody card-three">
                      <div className="cardbox-iconn">
                        <div className="icon-bxcard">
                          <img src={deposit} alt="" />
                        </div>
                        <div className="content-box-safty-secr">
                          <h5 className="medium-text-card">Deposit Limits</h5>
                          <p>
                            Set your weekly monthly and yearly Deposit limit, to
                            manage your risk perfectly.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 four">
                    <div className="safty-cardbody card-four">
                      <div className="cardbox-iconn">
                        <div className="icon-bxcard">
                          <img src={stamp} alt="" />
                        </div>
                        <div className="content-box-safty-secr">
                          <h5 className="medium-text-card">No Gambling</h5>
                          <p>
                            Fx Rumble it’s an ability game, we are not dealers.
                            You will always play against other players, standing
                            the same chance to thrive.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="dot-pic">
            <img src={lines} alt="" />
          </div>
        </section>

        {getAuth() ? null : (
          <section className="account-frm">
            <div className="shape-btom">
              <img src={shape_bg} alt="" />
            </div>
            {console.log(getAuth())}
            <div className="acount-frm-section">
              <div className="custom-container">
                <div className="account-frm-main">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="left-picc">
                        <img src={pic_act} alt="" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-bx-frm">
                        <div className="acont-form-bxx">
                          <div className="heading-main">
                            <h4>
                              <span className="title-medium">
                                Open Free Account
                              </span>
                            </h4>
                            <div className="texx-bxx">
                              Open your Fx Rumble account to start learn {"&"}
                              earn with us.
                            </div>
                          </div>
                          <form>
                            <div className="form-tabs">
                              <ul
                                className="nav nav-pills"
                                id="pills-tab"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <Link
                                    to="#"
                                    onClick={() => setState(true)}
                                    className={`nav-link ${
                                      state ? "active" : ""
                                    } `}
                                    id="pills-login-tab"
                                  >
                                    Login
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to="#"
                                    onClick={() => setState(false)}
                                    className={`nav-link ${
                                      !state ? "active" : ""
                                    } `}
                                    id="pills-register-tab"
                                  >
                                    Register
                                  </Link>
                                </li>
                              </ul>
                              <div
                                className="tab-content"
                                id="pills-tabContent"
                              >
                                <div
                                  className={`tab-pane fade ${
                                    state ? "show active" : ""
                                  }`}
                                  id="pills-login"
                                  role="tabpanel"
                                  aria-labelledby="pills-login-tab"
                                >
                                  <LoginForm />
                                </div>
                                <div
                                  className={`tab-pane fade ${
                                    !state ? "show active" : ""
                                  }`}
                                  id="pills-register"
                                  role="tabpanel"
                                  aria-labelledby="pills-register-tab"
                                >
                                  <SignUpForm />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="running-tournament">
          <div className="container">
            <div className="tournament-card">
              <div className="heading-main">
                <h4>
                  <span className="title-medium white-br-botm">
                    Running Tournaments
                  </span>
                </h4>
              </div>
              <div className="tournment-cardbox">
                <div className="row">
                  <div className="col-md-3 one">
                    <Link to="">
                      <div className="cards-inner">
                        <img src={club} alt="" />
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-3 two">
                    <Link to="">
                      <div className="cards-inner">
                        <img src={rumble} alt="" />
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-3 three">
                    <Link to="">
                      <div className="cards-inner">
                        <img src={Rumble_Satellite} alt="" />
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-3 four">
                    <Link to="">
                      <div className="cards-inner">
                        <img src={heads_up} alt="" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-d">
          <div className="container">
            <div className="count-box">
              <div className="heading-main">
                <h4>
                  <span className="title-medium white-br-botm">
                    FX RUMBLE EXPERIENCE
                  </span>
                </h4>
              </div>
              <div className="num-count-bx">
                <div className="row">
                  <div className="col-md-4 one">
                    <div className="num-card">
                      <div className="num-cardbx">
                        <div className="title">$ 100000+</div>
                        <div className="content">monthly prize pool</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 two">
                    <div className="num-card">
                      <div className="num-cardbx">
                        <div className="title">$ 0</div>
                        <div className="content">the account is free</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 three">
                    <div className="num-card">
                      <div className="num-cardbx">
                        <div className="title">1</div>
                        <div className="content">
                          free tournament every month
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 one">
                    <div className="num-card">
                      <div className="num-cardbx">
                        <div className="title">$ 1</div>
                        <div className="content">minimum tournament fee</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 two">
                    <div className="num-card">
                      <div className="num-cardbx">
                        <div className="title">24/7</div>
                        <div className="content">play every day every time</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 three">
                    <div className="num-card">
                      <div className="num-cardbx">
                        <div className="title">50+</div>
                        <div className="content">crypto and fiat supported</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        className={`modal  ${
          open ? "d-block" : ""
        } tournament-popup banner_modal`}
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => setOpen(false)}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="image-box">
                <img src={KycIcon} alt="iconbx"></img>
              </div>
              <div className="detail_box">
                <h5 className="title-sm">
                  <p dangerouslySetInnerHTML={{ __html: message }} />
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default Home;
