import { fetchEndpointData, postRequest }  from '../../Routes/axios'

export const getTournament = ({ email }) => {
  return async (dispatch) => {
    try {
      const response = await fetchEndpointData('/tournament/userTournament')
      if(response && response?.status && response.status === 200) {
        return dispatch({
          type: "GET_TOURNAMENT",
          payload: response.data,
        });
      }
    } catch (error) {
      return dispatch({
        type: "GET_TOURNAMENT_ERROR",
        payload: {
          user: {},
        },
      });
    }
  };
};

export const getSingleTournament = ({ name, payload }) => {
  return async (dispatch) => {
    try {
      let endpoint = name ? "/admin-tournament/get-admin":"/tournament/getby"
      const response = await fetchEndpointData(`${endpoint}/${payload}`)
      if(response && response?.data?.data) {
        localStorage.setItem("StartTournamentDate",response?.data?.data?.startDateTime)
        return dispatch({
          type: "GET_SINGLE_TOURNAMENT",
          payload: response.data?.data,
        });
      }
    } catch (error) {
      console.log(error)
      return dispatch({
        type: "GET_SINGLE_TOURNAMENT_ERROR",
        payload: {
          user: {},
        },
      });
    }
  };
};

export const creditCoin = ({ payload, name }) => {
  return async (dispatch) => {
    try {
      let endpoint = name ? "/admin-tournament":"/tournament"
      let res = await postRequest(`${endpoint}/creditCoin`, {
        tournament: payload,
        pair: "EURUSD",
      });

      return dispatch({
        type: "CREDIT_COIN",
      });
    } catch (error) {
      return dispatch({
        type: "CREDIT_COIN_ERROR",
        payload: {
          error: error,
        },
      });
    }
  };
};

export const getTournamentParticipants = ({ payload, name }) => {
  return async (dispatch) => {
    try {
      const endpoint = name ? "/admin-tournament" : "/tournament"
      const res = await fetchEndpointData(`${endpoint}/users/${payload}`)
      if(res && res.data) {
        res.data.participants.sort((a, b) => {
          return b.transactions.base_bal - a.transactions.base_bal;
        });
        return dispatch({
          type: "GET_PARTICIPANTS",
          payload: res.data.participants,
        });
      }
    } catch (error) {
      return dispatch({
        type: "GET_PARTICIPANTS_ERROR",
        payload: {
          error: error,
        },
      });
    }
  };
};

export const joinTournament = ({ id, email,name }) => {
  return async (dispatch) => {
    try {
      let endpoint = name?"/admin-tournament":"/tournament"
      let res = await postRequest(`${endpoint}/join`, {
        tournament_id: id,
        status: true,
      });
      dispatch(getTournament(email));
      return dispatch({
        type: "JOIN_TOURNAMENT",
        payload: res.data,
      });
  
    } catch (error) {
      return dispatch({
        type: "JOIN_TOURNAMENT_ERROR",
        payload: error,
      });
    }
  };
};


export const getTournamentResult = ({ payload, name }) => {
  return async (dispatch) => {
    try {
      const endpoint = name ? "/admin-tournament" : "/tournament"
      const res = await fetchEndpointData(`${endpoint}/users/${payload}`)
      if(res && res.data) {
        res.data.participants.sort((a, b) => {
          return b.transactions.base_bal - a.transactions.base_bal;
        });
        dispatch({
          type: "GET_SINGLE_TOURNAMENT",
          payload: res.data.tournament,
        });
        return dispatch({
          type: "GET_PARTICIPANTS",
          payload: res.data.participants,
        });
      }
    } catch (error) {
      return dispatch({
        type: "GET_PARTICIPANTS_ERROR",
        payload: {
          error: error,
        },
      });
    }
  };
};