import React from 'react'
import ScrollToTop from '../../hooks/ScrollToTop'
import { getAuth } from '../../Routes/Routes'
import Footer from '../Footer'
import Header from '../Header'

const TournamentDetails = () => {
    return (
      <>
      <Header auth={getAuth} />
        <section className="slider-inner-main play-slidermain">
        <div className="custom-container">
          <div className="inner-slidrbx">
            <div className="row align-items-center">
              <div className="col-md-6 one">
                <div className="left-texsldr">
                  <div className="subtitle-bx">
                    <span className="smal-line" />
                    <span className="subtitle">Tournament and Rules</span>
                  </div>
                  <div className="heading-topp">
                    <h1 className="title-large">Lorem ipsum dolor sit</h1>
                    <p className="medium-text box-space">Ut enim ad Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 two">
                <div className="right-pic-sldr">
                  <img src="images/tournament-baner.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-btom">
          <img src="images/sahpe-bg-blue.svg" alt="" />
        </div>
      </section>
      <Footer/>
      <ScrollToTop />
      </>
    )
}

export default TournamentDetails
