import React from "react";
import upload from "../../images/upload.svg";
import * as Constant  from "../Constant";
import { Link, useHistory } from "react-router-dom";
let countries = Constant.COUNTRY_LIST
import { postRequest }  from '../../Routes/axios'

const SignUpForm = () => {
  // const dispatch  = useDispatch();
  const [error, setError] = React.useState({
    fullName: null,
    email: null,
    password: null,
    country: null,
    msg: null,
  });
  const history = useHistory();
  const [values, setValues] = React.useState({
    fullName: '',
    email: '',
    password: '',
    country: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setError({
      ...error,
      [name]: null,
    });
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSignup = async () => {
    try {
      if (validate()) {
        let res = await postRequest(`/signup`, {
          fullName: values.fullName,
          password: values.password,
          email: values.email,
          country: values.country,
        });
        if (res.data.success) {
          return history.push("/linksent");
        }
      }
    } catch (err) {
      console.log('\n error in handleSignup:', err.message || err);
      setError({
        ...error,
        msg: err.message || "something went wrong"
      });
    }
  };

  const validate = () => {
    let input = values;
    let errors = {};
    let isValid = true;

    if (!input["fullName"]) {
      isValid = false;
      errors["fullName"] = "Please enter your fullname.";
    }

    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }

    if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }

    if (typeof input["password"] !== "undefined" && input["password"] != null) {
      if (input["password"].length < 6) {
        isValid = false;
        errors["password"] = "Please add at least 6 charachter.";
      }
    }

    if (!input["country"]) {
      isValid = false;
      errors["country"] = "Please enter country name.";
    }

    setError({
      ...errors,
    });
    return isValid;
  };
  return (
    <>
      
      <div className="form-filedbx">
        <div className="form-group">
          <div className="frm-bx-fldn">
          <input
            type="text"
            value={values.fullName}
            name="fullName"
            onChange={handleChange}
            className="form-control"
            id="exampleInputfullName"
            aria-describedby="emailHelp"
            placeholder="Full Name"
          />
          <span className="icon-form-fld">
            <img
              src="images/iconmonstr-user.svg"
              alt=""
            />
          </span>
          </div>
          {error.fullName && <span className="error">{error.fullName}</span>}
        </div>

        <div className="form-group">
          <div className="frm-bx-fldn">
          <input
            type="email"
            value={values.email}
            name="email"
            onChange={handleChange}
            className="form-control"
            id="exampleInputEmail"
            aria-describedby="emailHelp"
            placeholder="Email"
          />
          <span className="icon-form-fld">
            <img
              src="images/iconmonstr-email.svg"
              alt=""
            />
          </span>
          </div>
          {error.email && <span className="error">{error.email}</span>}
        </div>
        <div className="form-group">
          <div className="frm-bx-fldn">
            <input
              type="password"
              onChange={handleChange}
              name="password"
              value={values.password}
              className="form-control"
              id="exampleInputPassword"
              placeholder="Password"
            />
            <span className="icon-form-fld">
              <img
                src="images/iconmonstr-lock.svg"
                alt=""
              />
            </span>
          </div>
          {error.password && <span className="error">{error.password}</span>}
        </div>
        <div className="form-group">
          <div className="frm-bx-fldn">
          
          <select
            className="form-control"
            name="country"
            id="exampleSelectCountry"
            onChange={handleChange}
            value={values.country}
          >
            <option>Select Country</option>
            {countries.map((x, index) => {
              return (
                <option key={`o-${index}`} value={x.name}>{x.name}</option>
              );
            })}
          </select>
          <span className="icon-form-fld">
            <img src={upload} alt="" />
          </span>
          </div>
          {error.country && <span className="error">{error.country}</span>}
        </div>
        <div className="form-group">
          <div className="frm-bx-fldn btnlogin-bxx">
            <button
              type="button"
              name="button"
              className="btn-logn-frm btn-org"
              onClick={handleSignup}
            >
              <span className="icon-btnn">
                <img
                  src="images/pencil.svg"
                  alt=""
                />
              </span>
              <span className="btn-tex">
                Create Account
              </span>
            </button>
          </div>
        </div>
      </div>
     
  
    </>
  );
};

export default SignUpForm;
