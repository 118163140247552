import React from "react";

const AddCoinPopUp = ({
  coin,
  modal,
  handleSubmit,
  setCoin,
  setModal,
  loading,
  errorMsg,
  setErrorMsg
}) => {
  return (
    <div
      className={`modal  ${modal ? "d-block" : ""} profile-popup`}
      id="exampleModalCenter"
      tabIndex={-1}
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => {setModal(false); setErrorMsg(null)}}>
                ×
              </span>
            </button>
          </div>

          <div className="modal-body">
            <h5
              className="modal-title profile_title"
              id="exampleModalCenterTitle"
            >
              Add Fx-Coins
            </h5>
            <div className="inputbx_holder">
              <input
                type="text"
                value={coin}
                onChange={(e) => setCoin(e.target.value)}
                className="form-control"
                placeholder="Add coins"
                maxLength={6}
              />
              {errorMsg && <p className="error-box">{errorMsg}</p>}
            </div>
            <div className="actionbtns_holder">
              <ul>
                <li>
                  <button
                    className="btn-primaryy cnclbtn btn-createnw"
                    onClick={() => handleSubmit()}
                    type="button"
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </>
                    ) : (
                      <> Submit</>
                    )}
                  </button>
                </li>
                <li>
                  <button
                    className="sbmtbtn btn-org btn-bxx btn-primaryy"
                    onClick={() => {
                      setModal(false)
                      setErrorMsg(null)
                    }}
                    type="button"
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCoinPopUp;
