import React from "react";
import logo from "../../images/logo.svg";
import iconmonstr_lock from "../../images/iconmonstr-lock.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../Header";
import { getAuth } from "../../Routes/Routes";
import Footer from "../Footer";
import ScrollToTop from "../../hooks/ScrollToTop";

import { postRequest }  from '../../Routes/axios'
import { profileToggleAction } from "../../store/actions/toggleAction";
import { useDispatch } from "react-redux";

const ResetPassword = () => {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState(null);
  const history = useHistory();
  const params = useParams();
  const dispatch=useDispatch();

  const handlesubmit = async () => {
    try {
      if (password !== confirmPassword) {
        setError("Password does not match");
        return false;
      }
      let res = await postRequest(`/reset-password/${params.id}`, {
        password: password,
        id: params.id,
      });
      handleRedirect();

    } catch (err) {
      console.log('\n error in handlesubmit:', err);
      setError(err.message || 'Something went wrong.PLease contact to admin');
    }
    
  };

  const handleRedirect = () => {
    return history.push("/password-changed", { replace: true });
  };

  return (
    <>
      <Header auth={getAuth} />
      <div className="wrapper" onClick={()=>dispatch(profileToggleAction(false))}>
        <section className="login-box">
          <div className="container-login-bx">
            <div
              className="section-main-cardd"
              data-aos="fade-left"
              data-aos-easing="ease-in-sine"
            >
              <div className="row">
                <div className="col-md-6 col-lg-7 one">
                  <div className="welcm-bx">
                    <h4 className="medium-subtitle">Welcome to</h4>
                    <h1 className="title-large">FX RUMBLE</h1>
                    <p>Sed do eiusmod tempor incididunt</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 two">
                  <form>
                    <div className="login-form-new">
                      <div className="form-logn-bx">
                        <div className="logo-card">
                          <Link to="">
                            <img src={logo} alt="" />
                          </Link>
                        </div>
                        <div className="login-detail-frm form-layoutt">
                          <div className="headg-bx-lgn forgot-pssw">
                            <h4 className="heading-frmm">Reset Password</h4>
                            <p className="text">
                              Enter your new password below.
                            </p>
                          </div>

                          <div className="form-filedbx">
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <input
                                  type="password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  className="form-control"
                                  id="exampleInputPassword1"
                                  placeholder="Enter New Password"
                                />
                                <span className="icon-form-fld">
                                  <img src={iconmonstr_lock} alt="" />
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="exampleInputPassword1"
                                  value={confirmPassword}
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                                  placeholder="Confirm New Password"
                                />
                                <span className="icon-form-fld">
                                  <img src={iconmonstr_lock} alt="" />
                                </span>
                              </div>
                              {error && <span className="error">{error}</span>}
                            </div>

                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <button
                                  type="button"
                                  name="button"
                                  onClick={handlesubmit}
                                  className="btn-logn-frm btn-org"
                                >
                                  <span className="btn-tex">
                                    Update Password
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default ResetPassword;
