import React from "react";
import spades from "../../images/spades.png";
import question from "../../images/question.png";
import logo from "../../images/logo.svg";
import star from "../../images/star.png";
import promotions from "../../images/promotions.png";
import pencil from "../../images/pencil.png";
import enter from "../../images/enter.png";
import { Link } from "react-router-dom";
import profile_pic from "../../images/dummy.png";
import userlight from "../../images/user3.png";
import settinglight from "../../images/setting2.png";
import turn_off from "../../images/turn-off.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/actions/userActions";
import { postRequest } from "../../Routes/axios";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { profileToggleAction } from "../../store/actions/toggleAction";
const Header = ({ auth }) => {
  const dispatch = useDispatch();
  const { profileToggle } = useSelector((state) => state.profileToggle);
  const [sideMenu, setSideMenu] = React.useState(false);
  const [userMenu, setUserMenu] = React.useState(false);
  const [fade, setFade] = React.useState(false);
  const history = useHistory();
  const currentPath = history.location.pathname;
  console.log(currentPath);
  const user = useSelector((state) => state.user);
  let userDetails = user?.user;
  const { about, email, fullName, fxCoin, id, image } = userDetails;
  const handleClick = () => {
    document.body.classList.add("body-overflow-hide");
    setSideMenu(true);
    setFade(true);
  };
  const handleFadeMenu = () => {
    document.body.classList.remove("body-overflow-hide");
    setFade(false);
    setSideMenu(false);
  };

  React.useEffect(() => {
    dispatch(getUser());
  }, []);

  console.log(profileToggle, "profileToggle");
  return (
    <>
      <header>
        <div className="headermain bdr-top">
          <div className="custom-container">
            <div className="header-bx">
              <div className="row header-row">
                <div className="col-md-3 desk-view">
                  <div className="lef-list">
                    <ul>
                      <li>
                        <Link to="">
                          <span className="smal-icon">
                            <img src={spades} alt="" />
                          </span>
                          <span className="text-list-sml">
                            Responsible Gaming
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <span className="smal-icon">
                            <img src={question} alt="" />
                          </span>
                          <span className="text-list-sml">Help</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 logo-mobl-bx">
                  <div className="logo">
                    <Link to="" className="logo-pic">
                      <img src={logo} alt="" />
                    </Link>
                  </div>
                </div>

                <div className="col-md-3 desk-view">
                  <div className="right-list">
                    <ul>
                      <li>
                        <Link to="">
                          <span className="smal-icon">
                            <img src={star} alt="" />
                          </span>
                          <span className="text-list-sml">Rewards</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <span className="smal-icon">
                            <img src={promotions} alt="" />
                          </span>
                          <span className="text-list-sml">Promotion</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="button-toggle">
                  <button
                    type="button"
                    className="btn-toggle"
                    onClick={handleClick}
                  >
                    <span className="btn-barr"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--============  Mobile menu  start     =============--> */}

        <div
          className={`mobile-fade-menu ${fade ? "fade-bgg" : ""}`}
          onClick={handleFadeMenu}
        ></div>

        <div
          className={`navbar-nav menu-listbar mobile-menuu ${
            sideMenu ? "oepn-sidebar" : ""
          }`}
        >
          <div className="sidebar-main-menu">
            <div className="closebtnn">
              <button
                type="button"
                className="close-sidebar"
                onClick={handleFadeMenu}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>

            <ul className="menu-list-flx">
              <li className={currentPath == "/all-tournaments" ? "active" : ""}>
                <Link to="/all-tournaments">PLAY NOW</Link>
              </li>
              <li className={currentPath == "/how-to-play" ? "active" : ""}>
                <Link to="/how-to-play">HOW TO PLAY</Link>
              </li>
              <li
                className={currentPath == "/tournament-rules" ? "active" : ""}
              >
                <Link to="/tournament-rules">TOURNAMENTS AND RULES</Link>
              </li>
              <li className={currentPath == "/school" ? "active" : ""}>
                <Link to="/school">FX RUMBLE SCHOOL</Link>
              </li>
              <li
                className={
                  currentPath == "/deposits-and-withdrawls" ? "active" : ""
                }
              >
                <Link to="/deposits-and-withdrawls">
                  DEPOSITS AND WITHDRAWALS
                </Link>
              </li>
              <li className={currentPath == "/" ? "active" : ""}>
                <a href="https://rumblexchange.com" target="_blank">
                  RUMBLE EXCHANGE
                </a>
              </li>
              <li>
                <Link to="">Responsible Gaming</Link>
              </li>
              <li>
                <Link to="">Help</Link>
              </li>
              <li>
                <Link to=""> Rewards</Link>
              </li>
              <li>
                <Link to=""> Promotion</Link>
              </li>
            </ul>

            {!auth() ? (
              <ul className="navbar-btn-lst">
                <li className="joinbtn btn-topbr">
                  <Link to="" className="btn-org btn-bxx">
                    <span className="icon-btnn">
                      <img src={pencil} alt="" />
                    </span>
                    <span className="btn-tex">Join Us</span>
                  </Link>
                </li>
                <li className="btn-bdr btn-topbr">
                  <Link to="" className="loginbtn btn-bxx">
                    <span className="icon-btnn">
                      <img src={enter} alt="" />
                    </span>
                    <span className="btn-tex">Login</span>
                  </Link>
                </li>
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
      </header>

      <section className="navbar-main desk-view">
        <div className="custom-container">
          <div className="navbar-box">
            <nav className="navbar navbar-expand-md menubar-top">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse navbarmenu"
                id="navbarNavAltMarkup"
              >
                <div className="navbar-nav menu-listbar">
                  <ul className="menu-list-flx">
                    <li
                      className={
                        currentPath == "/all-tournaments" ? "active" : ""
                      }
                    >
                      <Link to="/all-tournaments">PLAY NOW</Link>
                    </li>
                    <li
                      className={currentPath == "/how-to-play" ? "active" : ""}
                    >
                      <Link to="/how-to-play">HOW TO PLAY</Link>
                    </li>
                    <li
                      className={
                        currentPath == "/tournament-rules" ? "active" : ""
                      }
                    >
                      <Link to="/tournament-rules">TOURNAMENTS AND RULES</Link>
                    </li>
                    <li className={currentPath == "/school" ? "active" : ""}>
                      <Link to="/school">FX RUMBLE SCHOOL</Link>
                    </li>
                    <li
                      className={
                        currentPath == "/deposits-and-withdrawls"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/deposits-and-withdrawls">
                        DEPOSITS AND WITHDRAWALS
                      </Link>
                    </li>
                    <li className={currentPath == "/" ? "active" : ""}>
                      <a href="https://rumblexchange.com" target="_blank">
                        RUMBLE EXCHANGE
                      </a>
                    </li>
                  </ul>
                  {auth() ? (
                    <ul className="navbar-btn-lst">
                      <li className="joinbtn btn-topbr profilbtn-drop">
                        <div className="dropdown">
                          <button
                            className="btn  dropdown-toggle profile-btnn"
                            type="button"
                            onClick={() =>
                              dispatch(profileToggleAction(!profileToggle))
                            }
                            id="dropdownMenuButton"
                          >
                            <span className="prof-pic">
                              <img
                                src={image ?? profile_pic}
                                alt=""
                                className="head_pic"
                              />
                            </span>
                            <span className="neme">{fullName}</span>
                          </button>
                          <div
                            className={`dropdown-menu ${
                              profileToggle ? "show" : ""
                            } `}
                          >
                            <ul>
                              <li>
                                <Link className="dropdown-item" to="/profile">
                                  <span className="pic-iconn">
                                    <img src={userlight} alt="" />
                                  </span>
                                  <span className="texx"> View Profile</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/edit-profile"
                                >
                                  <span className="pic-iconn">
                                    <img src={settinglight} alt="" />
                                  </span>
                                  <span className="texx">Settings</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="kyc-verified"
                                  // onClick={()=>calltoken()}
                                >
                                  <span className="pic-iconn">
                                    <VerifiedOutlinedIcon
                                      fontSize={"md"}
                                      style={{ height: "19px", width: "19px" }}
                                    />
                                  </span>
                                  <span className="texx"> Verify Me</span>
                                  {/* </a> */}
                                </Link>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item log-out"
                                  onClick={() => {
                                    localStorage.removeItem("auth-token");
                                    localStorage.removeItem("accessToken");
                                    localStorage.removeItem("FXuser");
                                    history.push("/");
                                  }}
                                  to=""
                                >
                                  <span className="pic-iconn">
                                    <img src={turn_off} alt="" />
                                  </span>
                                  <span className="texx">Logout</span>
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    <ul className="navbar-btn-lst">
                      <li className="joinbtn btn-topbr">
                        <Link to="/signup" className="btn-org btn-bxx">
                          <span className="icon-btnn">
                            <img src={pencil} alt="" />
                          </span>
                          <span className="btn-tex">Join Us</span>
                        </Link>
                      </li>
                      <li className="btn-bdr btn-topbr">
                        <Link to="/login" className="loginbtn btn-bxx">
                          <span className="icon-btnn">
                            <img src={enter} alt="" />
                          </span>
                          <span className="btn-tex">Login</span>
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
