import React from "react";
import "./AddCard.css";
import {
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Footer from "../Footer";
import { getAuth } from "../../Routes/Routes";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import { addCard, Loading, getCard } from "../../store/actions/stripeActions";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import { toast } from "react-toastify";
import { profileToggleAction } from "../../store/actions/toggleAction";



const config = require(`../../enviorment/${process.env.NODE_ENV}.jsx`).config;
const stripePromise = loadStripe(
  config.stipe_key
);


const MyCheckoutForm = () => {
  const history = useHistory();
  const [error, setError] = React.useState(null);
  const stripeState = useSelector((state) => state.stripe);
  let { loading, cards } = stripeState;
  const dispatch = useDispatch();
  const [name, setName] = React.useState("");
  const stripe = useStripe();
  const elements = useElements();
  React.useEffect(() => {
    dispatch(getCard());
  }, []);

  const handleSubmit = async (event) => {
    dispatch(Loading(true));
    if (!stripe || !elements) return false;
    const cardElement = elements.getElement(CardNumberElement)


     const user = JSON.parse(localStorage.getItem("FXuser"));
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: name,
        email: user?.email,
      },
    });

    if (error) {
      console.log(error?.message);
      setError(error?.message);
      dispatch(Loading(false));
      return false;
    }

    // /stripe/payment-methods
    let token = await stripe.createToken(cardElement);
    console.log({token})
    if (!error) dispatch(addCard(paymentMethod.id, token.token.id))
    .then((res) => {
     if(res?.payload?.error?.response?.data?.error?.code==="incorrect_cvc") {toast.error("Your card's security code is incorrect.")}
     else if(res?.payload?.error?.response?.data?.error?.code==="card_declined"){
      toast.error("Your card is declined.")
     }

     else{
      toast.success("Card Added Successfully.")
      return history.push("/profile");
    }
    dispatch(Loading(false))
    })
  };
  

  const inputStyle = {
    fontWeight: "500",
    fontSize: "22px",
    background: "#1E2A3B",
    border: "1px solid #39475B",
    height: "100px",
    color: "#919FB4",
    fontSize: "18px",
    fontWeight: "300",
    padding: "15px",
  };

  return (
    <>
      <Header auth={getAuth} />
      <div className="wrapper" onClick={()=>dispatch(profileToggleAction(false))}>
        <section className="login-box">
          <div className="container-login-bx card-conatiner">
            <div
              className="section-main-cardd"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              <div className="row">
                <div className="col-md-12">
                  <form>
                    <div className="login-form-new ">
                      <div className="form-logn-bx">
                        <div className="login-detail-frm form-layoutt pt-0">
                          <div className="headg-bx-lgn ">
                            <h4 className="heading-frmm">Add Card</h4>
                          </div>
                          <div className="form-filedbx">
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <CardNumberElement
                                  options={{
                                    style: {
                                      base: inputStyle,
                                    },
                                  }}
                                  onChange={() => setError(null)}
                                  className={`checkout_form_control ${error === 'Your card number is incomplete.' ? "change_border_color" : ""}`}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="card-flds">
                                <div className="frm-bx-fldn">
                                  <CardExpiryElement
                                    options={{
                                      style: {
                                        base: inputStyle,
                                      },
                                    }}
                                    onChange={() => setError(null)}
                                    className={`checkout_form_control ${error === `Your card's expiration date is incomplete.` ? "change_border_color" : ""}`}
                                  />
                                </div>

                                <div className="frm-bx-fldn">
                                  <CardCvcElement
                                    options={{
                                      style: {
                                        base: inputStyle,
                                      },
                                    }}
                                    onChange={() => setError(null)}
                                    className={`checkout_form_control ${error === `Your card's security code is incomplete.` ? "change_border_color" : ""}`}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <input
                                  type="text"
                                  value={name}
                                  onChange={(e) => {
                                    setName(e.target.value);
                                    setError(null);
                                  }}
                                  className="form-control"
                                  id="exampleInputPassword1"
                                  placeholder="Add Card Name"
                                />
                              </div>
                            </div>
                            {error && <span className="error">{error}</span>}
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <button
                                  onClick={handleSubmit}
                                  type="button"
                                  name="button"
                                  className="btn-logn-frm btn-org"
                                >
                                  {loading && !error ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      Loading...
                                    </>
                                  ) : (
                                    <span className="btn-tex">
                                      {cards?.length > 0 ? "Update" : "Add"}
                                      Card
                                    </span>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};

const AddCard = () => {
  return (
    <Elements stripe={stripePromise}>
      <MyCheckoutForm />
    </Elements>
  );
};
export default AddCard;
