import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import logo from "../../images/logo.svg";
import { getAuth } from "../../Routes/Routes";
import Footer from "../Footer";
import Header from "../Header";
import { fetchEndpointData } from '../../Routes/axios'
import { profileToggleAction } from "../../store/actions/toggleAction";
import { useDispatch } from "react-redux";


const AccountCreated = () => {
  const [success, setSuccess] = React.useState(null);
  const [message, setMessage] = React.useState("");
  const history = useHistory();
  const params = useParams();
  const dispatch=useDispatch();
  React.useEffect(() => {
    const verify = async () => {
      try {
        const res = await fetchEndpointData(`/verify/${params?.id}`)
        if(res && res.data) {
          setSuccess(res.data?.success);
          return history.push("/login");
        }
      } catch (err) {
        console.error(err.response);
          setSuccess(err.response.data.success);
          setMessage(err.response.data.message);
      }
    };
    verify();
  }, []);

  React.useEffect(() => {
    if (!params?.id) {
      return history.push("/");
    }
  }, []);

  return (
    <>
      <Header auth={getAuth} />
      <section className="login-box" onClick={()=>dispatch(profileToggleAction(false))}>
        <div className="container-login-bx">
          <div
            className="section-main-cardd"
            dataAos="fade-up"
            dataAosEasing="ease-in-sine"
          >
            <div className="row">
              <div className="col-md-6 col-lg-7 one">
                <div className="welcm-bx">
                  <h4 className="medium-subtitle">Welcome to</h4>
                  <h1 className="title-large">FX RUMBLE</h1>
                  <p>Sed do eiusmod tempor incididunt</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-5 two">
                <form>
                  <div className="login-form-new">
                    <div className="form-logn-bx">
                      <div className="logo-card">
                        <Link to="">
                          <img src={logo} alt="" />
                        </Link>
                      </div>
                      <div className="login-detail-frm form-layoutt change-passwd">
                        {success === true ? (
                          <>
                            <div className="tickcrcl">
                              <div className="anim-tick">
                                <svg
                                  version="1.1"
                                  id="tick"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 37 37"
                                  style={{ enableBackground: "new 0 0 37 37" }}
                                  xmlSpace="preserve"
                                >
                                  <path
                                    className="circ path"
                                    style={{
                                      fill: "none",
                                      stroke: "#fff",
                                      strokeWidth: "2",
                                      strokeLineJoin: "round",
                                      strokeMiterLimit: "10",
                                    }}
                                    d="
                            M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
                                  />
                                  <polyline
                                    className="tick path"
                                    style={{
                                      fill: "none",
                                      stroke: "#fff",
                                      strokeWidth: "2",
                                      strokeLineJoin: "round",
                                      strokeMiterLimit: "10",
                                    }}
                                    points="
                            11.6,20 15.9,24.2 26.4,13.8 "
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className="headg-bx-lgn forgot-pssw">
                              <h4 className="heading-frmm grey-clr congrt-tex">
                                Congratulations!
                              </h4>
                              <p className="text">
                                Your account has been created successfully.
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="headg-bx-lgn forgot-pssw">
                              <h4 className="heading-frmm grey-clr congrt-tex">
                                Sorry!
                              </h4>
                              <p className="text">{message}</p>
                            </div>
                          </>
                        )}

                        <div className="form-filedbx">
                          <div className="form-group">
                            <div className="frm-bx-fldn">
                              <button
                                onClick={() => history.push("/")}
                                type="button"
                                name="button"
                                className="btn-logn-frm btn-org"
                              >
                                <span className="btn-tex">Go To Homepage</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AccountCreated;
