import React, { Component } from "react";

class Timer extends Component {
  constructor(props) {
    super(props);
    this.countDownId = null;
    this.state = {
      hours: 0,
      minutes: 0,
      seconds: 0,
      expired: false,
      timeLeft: 0,
    };
  }

  componentDidMount() {
    this.countDownId = setInterval(this.timerInit, 1000);
  }

  componentWillUnmount() {
    if (this.countDownId) {
      clearInterval(this.countDownId);
    }
  }

  timerInit = () => {
    let { startDate, endTime } = this.props;
    const now = new Date().getTime();
    const start = new Date(endTime).getTime();
    if(!startDate || startDate == null || startDate == NaN || startDate == undefined || startDate == 10000 || startDate == '10000'){
      this.setState({
        hours: 0,
        minutes: 0,
        seconds: 0,
        expired: false,
      });
      return;
    }
    // if (!startDate || startDate == null || startDate == NaN || startDate == undefined || startDate == 1000 ) {
    //   let startDate = new Date()
    //   startDate.setSeconds(start.getSeconds() + 5);
    //   console.log("startDate1111============================",startDate)
    // }
    const countDownStartDate = new Date(startDate).getTime();
    const distance = countDownStartDate - now;
    const startTime = countDownStartDate - start;
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    // For countdown is finished
    if (distance < 0) {
      clearInterval(this.countDownId);
      this.setState({
        hours: 0,
        minutes: 0,
        seconds: 0,
        expired: true,
      });
      this.props.callBack();
      return;
    }
    let time = (distance / startTime) * 100;
    this.setState({ hours, minutes, seconds, expired: false, timeLeft: time });
  };

  render() {
    const { days, hours, minutes, seconds, expired, timeLeft } = this.state;
    if (expired) {
      return <div className="expired">Expired :{`(`}</div>;
    }
    return (
      <div className="circle-placeloder">
        <div className="circle-rotate-bx">
          <svg className="timer-crcl">
            <circle
              className="circl-bx"
              cx={90}
              cy={90}
              r={75}
              stroke="#3D506D"
              strokeWidth={8}
            />
            <circle
              className="circl-placehldr"
              cx={90}
              cy={90}
              r={75}
              stroke="#EB1E24"
              strokeWidth={8}
              style={{ strokeDasharray: `${(timeLeft / 100) * 471} 471` }}
            />
          </svg>
        </div>

        <div className="timer-countt">
          <ul>
            <li>
              <div className="counter-box-main">
                <span className="hrs-count count-frame">{`0${hours}`}</span>
                <span className="hrs-tex count-frame-btm">hrs</span>
              </div>
            </li>
            <li>
              <div className="counter-box-main">
                <span className="min-count count-frame">
                  {minutes < 10 ? `0` + minutes : minutes}
                </span>
                <span className="min-tex count-frame-btm">mins</span>
              </div>
            </li>
            <li>
              <div className="counter-box-main">
                <span className="secd-count count-frame">
                  {seconds < 10 ? `0` + seconds : seconds}
                </span>
                <span className="secd-tex count-frame-btm">sec</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default Timer;
