import * as React from 'react';
import './App.css';
import { TVChartContainer } from './Components/TVChartContainer/index';
import { ProtectedRoute, PublicRoute, getAuth } from "./Routes/Routes";
import { Route, Switch } from "react-router-dom";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./Components/Home";
import Login from "./Components/Login";
import SignUp from "./Components/SignUp";
import ForgotPassword from "./Components/ForgotPassword";
import EditProfile from "./Components/EditProfile";
import VerifyMe from "./Components/VerifyMe";
import AccountCreated from "./Components/AccountCreated";
import LinkSend from "./Components/LinkSend";
import DepositsWithdrawls from "./Components/DepositsWithdrawls";
import Profile from './Components/Profile'
import CreateTournament from './Components/CreateTournament'
import InviteUsers from './Components/InviteUsers'
import PasswordChanged from './Components/PasswordChanged'
import LinkSent from "./Components/LinkSent";
import OnGoingTournament from './Components/OnGoingTournament'
import ResetPassword from './Components/ResetPassword'
import TournamentRule from './Components/TournamentRule'
import PlayGround from './Components/PlayGround'
import School from './Components/School'
import SchoolDetails from './Components/SchoolDetails'
import WonTournament from './Components/WonTournament'
import Wallet from './Components/Wallet'
import HowToPlay from './Components/HowToPlay'
import AddWallet from './Components/AddWallet'
import AddCard from './Components/AddCard'
import AddWalletSuccessfully from './Components/AddWalletSuccessfully'
import TournamentDetails from './Components/TournamentDetails'
import AddBankDetail from './Components/AddBankDetail'
import VerifyBankDetail from './Components/VerifyBankDetail'

function App() {
		return (
        <>
      <div className={ 'App' }>
				<Switch>
				<Route path="/" exact component={Home} />
				<PublicRoute auth={getAuth} path="/login" exact component={Login} />
				<PublicRoute auth={getAuth} path="/signup" exact component={SignUp} />
				<Route path="/forgotpassword" exact component={ForgotPassword} />
				<Route path="/edit-profile" exact component={EditProfile} />
        <Route path="/kyc-verified" exact component={VerifyMe} />
				<Route path="/verifyUser/:id" exact component={AccountCreated} />
				<Route path="/linksent" exact component={LinkSend} />
				<Route
					path="/deposits-and-withdrawls"
					exact
					component={DepositsWithdrawls}
					/>
				<ProtectedRoute
					auth={getAuth}
					path="/profile"
					exact
					component={Profile}
					/>
				<ProtectedRoute
					auth={getAuth}
					path="/create-tournament/:type"
					exact
					component={CreateTournament}
					/>


<ProtectedRoute
          auth={getAuth}
          path="/invite-users"
          exact
          component={InviteUsers}
        />
        <Route path="/password-changed" exact component={PasswordChanged} />
        <Route path="/link-sent" exact component={LinkSent} />
        <ProtectedRoute
          auth={getAuth}
          path="/all-tournaments/:id?"
          exact
          component={OnGoingTournament}
        />
        <Route path="/reset-password/:id" exact component={ResetPassword} />
        <Route path="/tournament-rules" exact component={TournamentRule} />
        <ProtectedRoute
          auth={getAuth}
          path="/playground/:id/:type/:name?"
          exact
          component={PlayGround}
        />
        <Route path="/school" exact component={School} />
        <Route path="/school-details" exact component={SchoolDetails} />
        <ProtectedRoute
          auth={getAuth}
          path="/results/:id/:name?"
          exact
          component={WonTournament}
        />
		<ProtectedRoute
          auth={getAuth}
          path="/wallet"
          exact
          component={Wallet}
        />
        <Route path="/tournament-details" exact component={TournamentDetails} />
        <Route path="/how-to-play" exact component={HowToPlay} />
        <ProtectedRoute
          auth={getAuth}
          path="/add"
          exact
          component={AddWallet}
        />
        <ProtectedRoute
          auth={getAuth}
          path="/add-card"
          exact
          component={AddCard}
        />
        <ProtectedRoute
          auth={getAuth}
          path="/add-bank"
          exact
          component={AddBankDetail}
        />
        <ProtectedRoute
          auth={getAuth}
          path="/verify-bank"
          exact
          component={VerifyBankDetail}
        />
        <Route path="/successfully" exact component={AddWalletSuccessfully} />

				{/*---Temp Url---*/}
				<Route path="/tvChart" exact component={TVChartContainer} />
				<Route path="/play" exact component={PlayGround} />
				</Switch>
			</div>
<ToastContainer />
        </>
		);
	}
export default App;
