import React from "react";
import music from "../../images/music.png";
import Timer from '../Timer'
import {getTournamentParticipants} from "../../store/actions/tournamentActions";
import { useDispatch } from "react-redux";
import swal from 'sweetalert';
import {memo } from "react";
import { parse } from "date-fns";

import { fetchEndpointData, postRequest }  from '../../Routes/axios'

const GraphSideBar = ({
  bidPrice,
  askPrice,
  seconds,
  qty,
  seconds2,
  redirect,
  error,
  param,
  setTransactionsList
}) => {

  const [buyLoadings, setBuyLoadings] = React.useState(false);
  const [sellLoadings, setSellLoadings] = React.useState(false);
  const [closeLoadings, setCloseLoadings] = React.useState(false);
  const [transactionLists, setTransactionsLists] = React.useState([]);

  const dispatch = useDispatch();

  
  const transactionss = async () => {
    try {
      let endpoint = param && param.name ? "/admin-tournament" : "/tournament"
      const response = await fetchEndpointData(`${endpoint}/transactions/${param.id}`)
      if(response && response.data) {
        setTransactionsList(response.data.data);
      }
    } catch (error) {
      swal("",`Something went wrong`, "error");
      console.log(error.response || error.message || error);
    }
  };

  const handleBuys = async (e) => {
    try {
    setBuyLoadings(true);
    if (bidPrice === undefined || bidPrice === null) {
      setBuyLoadings(false)
      swal("",`Please wait for bid price.`, "error");
      return false;
    }
    if (!qty) {
      setBuyLoadings(false);
      return false;
    }
    if (param.base_bal === 0 || param.base_bal < 0) {
      swal("",`You don't have coins.`, "error");
      setBuyLoadings(false);
      return false;
    }
    if(parseFloat(bidPrice) > param.base_bal){
      swal("",`You don't have coins.`, "error");
      setBuyLoadings(false);
      return false;
    }
    if (qty > param.base_bal) {
      swal("",`Maximum ${param.base_bal} allowed.`, "error");
      setBuyLoadings(false);
      return false;
    }
    
      let endpoint = param.name === "admin" ? "/admin-tournament" : "/tournament"
      let res = await postRequest(`${endpoint}/buyCoin`, {
        tournament: param.id,
        amount: parseFloat(bidPrice) * qty,
        pair: "EURUSD",
        price: parseFloat(bidPrice),
        qty: qty,
      });


      setBuyLoadings(false);
      transactionss();
      dispatch(getTournamentParticipants({ payload: param.id, name:param.name }));
    } catch (error) {
      swal("",`Something went wrong`, "error");
      setBuyLoadings(false);
    }
  };

  const handleSellCoin = async () => {
    try {
    setSellLoadings(true);
    if (askPrice === undefined || askPrice === null) {
      setSellLoadings(false)
      swal("",`Please wait for bid price.`, "error");
      return false;
    }
    if (!qty) {
      setSellLoadings(false);
      return false;
    }
    // if (param.other_bal === 0) {
    //   swal("","Please buy to sell.", "error");
    //   setSellLoadings(false);
    //   return false;
    // }
    // if (param.other_bal == -1) {
    //   swal("",`Please buy to sell`, "error");
    //   setSellLoadings(false);
    //   return false;
    // }
    // if (qty > param.other_bal) {
    //   swal("",`Maximum ${param.other_bal} allowed.`, "error");
    //   setSellLoadings(false);
    //   return false;
    // }
      let endpoint = param.name === "admin" ? "/admin-tournament" : "/tournament"
      let res = await postRequest(`${endpoint}/sellCoin`, {
        tournament: param.id,
        amount: parseFloat(askPrice) * qty,
        pair: "EURUSD",
        price: parseFloat(askPrice),
        qty: qty,
      });

      setSellLoadings(false);
      transactionss();
      dispatch(getTournamentParticipants({ payload: param.id, name:param.name }));
    } catch (error) {
      swal("",`Something went wrong`, "error");
      setSellLoadings(false);
    }
  };

  const handleClose = async (e) => {
    try {
    setCloseLoadings(true);
    if (bidPrice === undefined || bidPrice === null) {
      setCloseLoadings(false)
      swal("",`Please wait for bid price.`, "error");
      return false;
    }
    if(param.other_bal < 0 && ((parseFloat( Math.abs(param.other_bal)) * parseFloat(bidPrice)) > param.base_bal)){
      swal("",`You don't have coins.`, "error");
      setCloseLoadings(false);
      return false;
    }
    if (param.other_bal == 0) {
      swal("",`Please buy or sell to close Trade`, "error");
      setCloseLoadings(false);
      return false;
    }
    
      let endpoint = param.name === "admin" ? "/admin-tournament" : "/tournament"
      let res = await postRequest(`${endpoint}/closeCoin`, {
        tournament: param.id,
        amount: parseFloat(bidPrice) * qty,
        pair: "EURUSD",
        price: parseFloat(bidPrice),
      });

      setCloseLoadings(false);
      transactionss();
      dispatch(getTournamentParticipants({ payload: param.id, name:param.name }));
    } catch (error) {
      swal("",`Something went wrong`, "error");
      setCloseLoadings(false);
    }
  };
  
  return (
    <div className="right-sidebarr">
      <div className="right-detail-match">
        <div className="time-leftbx">
          <div className="title-right">Time Left</div>
          <div className="time-left-card">
            <div className="timecounter-bx">
              <Timer
                callBack={() => redirect()}
                endTime={seconds2}
                startDate={seconds}
              />
            </div>
          </div>
        </div>
        <div className="amount-detl">
          <div className="amount-title">Bid Price</div>
          <div className="amount-detail">
            <span className="doller-sign">$</span>
            <span className="amount-num">{bidPrice}</span>
          </div>
        </div>

        <div className="buybtn-main">
          <div className="buybtnbx">
            <button
              onClick={handleBuys}
              disabled={buyLoadings}
              className="btn-buy btn-trade"
            >
              {buyLoadings ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </>
              ) : (
                <>
                  {bidPrice && parseFloat(bidPrice)}
                  {bidPrice ? <br /> : ""}
                  Buy <i className="fas fa-chevron-up" />
                </>
              )}
            </button>
          </div>
          <div className="buybtnbx">
            <button
              onClick={handleSellCoin}
              disabled={sellLoadings}
              className="btn-sell btn-trade"
            >
              {sellLoadings ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </>
              ) : (
                <>
                  {askPrice && parseFloat(askPrice)}
                  {askPrice ? <br /> : ""}
                  SELL <i className="fas fa-chevron-down" />
                </>
              )}
            </button>
          </div>
          <div className="buybtnbx">
            <button
              onClick={handleClose}
              disabled={closeLoadings}
              className="btn-sell btn-close-trade"
            >
              {closeLoadings ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </>
              ) : (
                <>
                 Close <i className="fas fa-times" />
                </>
              )}
            </button>
          </div>
        </div>
        <div className="music-rumbl">
          {/* <img src={music} alt="" /> */}
        </div>
      </div>
    </div>
  );
};

export default memo(GraphSideBar);
