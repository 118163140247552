import React from "react";
import logo from "../../images/logo.svg";
import upload from "../../images/upload.svg";
import pencil from "../../images/pencil.svg";
import iconmonstr_user from "../../images/iconmonstr-user.svg";
import iconmonstr_email from "../../images/iconmonstr-email.svg";
import iconmonstr_lock from "../../images/iconmonstr-lock.svg";
import { Link, useHistory } from "react-router-dom";
import Header from "../Header";
import { getAuth } from "../../Routes/Routes";
import Footer from "../Footer";
import ScrollToTop from "../../hooks/ScrollToTop";
import * as Constant  from "../Constant";

let countries = Constant.COUNTRY_LIST
import { postRequest }  from '../../Routes/axios'
import { profileToggleAction } from "../../store/actions/toggleAction";
import { useDispatch } from "react-redux";

const SignUp = () => {
  const dispatch=useDispatch();
  const [error, setError] = React.useState({
    fullName: null,
    email: null,
    password: null,
    country: null,
    msg: null,
  });
  const history = useHistory();
  const [state, setState] = React.useState({
    fullName: "",
    email: "",
    password: "",
    country: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setError({
      ...error,
      [name]: null,
    });
    setState({
      ...state,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    try {
      if (validate()) {
        let res = await postRequest(`/signup`, state);
        if (res && res.data && res.data.success) {
          return history.push("/linksent");
        }
      }
    } catch (err) {
      console.log('\n handleSubmit error:', err.message || err);
      setError({
        ...error,
        msg: err.message || 'something went wrong',
      });

    }
   
  };

  const validate = () => {
    let input = state;
    let errors = {};
    let isValid = true;

    if (!input["fullName"]) {
      isValid = false;
      errors["fullName"] = "Please enter your fullName.";
    }

    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }

    if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }

    if (typeof input["password"] !== "undefined" && input["password"] != null) {
      if (input["password"].length < 6) {
        isValid = false;
        errors["password"] = "Please add at least 6 charachter.";
      }
    }

    if (!input["country"]) {
      isValid = false;
      errors["country"] = "Please enter country name.";
    }

    setError({
      ...errors,
    });
    return isValid;
  };
  return (
    <>
      <Header auth={getAuth} />
      <div className="wrapper" onClick={()=>dispatch(profileToggleAction(false))}>
        <section className="login-box">
          <div className="container-login-bx">
            <div
              className="section-main-cardd"
              data-aos="fade-down"
              data-aos-easing="ease-in-sine"
            >
              <div className="row">
                <div className="col-md-6 col-lg-7 one">
                  <div className="welcm-bx">
                    <h4 className="medium-subtitle">Welcome to</h4>
                    <h1 className="title-large">FX RUMBLE</h1>
                    <p>Sed do eiusmod tempor incididunt</p>
                  </div>
                </div>

                <div className="col-md-6 col-lg-5 two">
                  <form>
                    <div className="login-form-new signup-headg">
                      <div className="form-logn-bx">
                        <div className="logo-card">
                          <Link to="">
                            <img src={logo} alt="" />
                          </Link>
                        </div>
                        <div className="login-detail-frm form-layoutt">
                          <div className="headg-bx-lgn">
                            <h4 className="heading-frmm">Sign Up</h4>
                          </div>
                          <div className="form-filedbx">
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <input
                                  type="text"
                                  value={state.fullName}
                                  name="fullName"
                                  onChange={handleChange}
                                  className="form-control"
                                  id="exampleInputfullName"
                                  aria-describedby="emailHelp"
                                  placeholder="Full Name"
                                />
                                <span className="icon-form-fld">
                                  <img src={iconmonstr_user} alt="" />
                                </span>
                              </div>
                              {error.fullName && (
                                <span className="error">{error.fullName}</span>
                              )}
                            </div>
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <input
                                  type="email"
                                  value={state.email}
                                  name="email"
                                  onChange={handleChange}
                                  className="form-control"
                                  id="exampleInputEmail"
                                  aria-describedby="emailHelp"
                                  placeholder="Email"
                                />
                                <span className="icon-form-fld">
                                  <img src={iconmonstr_email} alt="" />
                                </span>
                              </div>
                              {error.email && (
                                <span className="error">{error.email}</span>
                              )}
                            </div>
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <input
                                  type="password"
                                  onChange={handleChange}
                                  name="password"
                                  value={state.password}
                                  className="form-control"
                                  id="exampleInputPassword1"
                                  placeholder="Password"
                                />
                                <span className="icon-form-fld">
                                  <img src={iconmonstr_lock} alt="" />
                                </span>
                              </div>
                              {error.password && (
                                <span className="error">{error.password}</span>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <select
                                  className="form-control"
                                  name="country"
                                  id="exampleSelectCountry"
                                  onChange={handleChange}
                                  value={state.country}
                                >
                                  <option>Select Country</option>
                                  {countries.map((x, index) => {
                                    return (
                                      <option key={`o-${index}`} value={x.name}>{x.name}</option>
                                    );
                                  })}
                                </select>
                                <span className="icon-form-fld">
                                  <img src={upload} alt="" />
                                </span>
                              </div>
                              {error.country && (
                                <span className="error">{error.country}</span>
                              )}
                            </div>
                            {error.msg && (
                              <span className="error">{error.msg}</span>
                            )}
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <button
                                  type="button"
                                  name="button"
                                  onClick={handleSubmit}
                                  className="btn-logn-frm btn-org"
                                >
                                  <span className="icon-btnn">
                                    <img src={pencil} alt="" />
                                  </span>
                                  <span className="btn-tex">
                                    Create Account
                                  </span>
                                </button>
                              </div>
                            </div>

                            <div className="sign-accont">
                              Already have an account?
                              <Link to="/login">Sign in</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default SignUp;
