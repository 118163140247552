import React, { useEffect } from "react";
import play_slider1 from "../../images/play-slider1.png";
import shape_bg_blue from "../../images/sahpe-bg-blue.svg";
import play1 from "../../images/play1.jpg";
import play2 from "../../images/play2.jpg";
import play3 from "../../images/play3.jpg";
import play4 from "../../images/play4.jpg";
import play5 from "../../images/play5.jpg";
import play6 from "../../images/play6.jpg";
import video_tut1 from "../../images/video-tut1.jpg";
import video_tut2 from "../../images/video-tut2.jpg";
import video_tut3 from "../../images/video-tut3.jpg";
import video_tut4 from "../../images/video-tut4.jpg";
import video_tut5 from "../../images/video-tut5.jpg";
import video_tut6 from "../../images/video-tut6.jpg";
import stock from "../../images/stock.svg";
import trading from "../../images/trading.svg";
import stock_market from "../../images/stock-market.svg";
import speech_bubble from "../../images/speech-bubble.svg";
import time from "../../images/time.svg";
import increasing_bar_graph from "../../images/increasing-bar-graph.svg";
import Footer from "../Footer";
import { getAuth } from "../../Routes/Routes";
import Header from "../Header";
import ScrollToTop from "../../hooks/ScrollToTop";
import { profileToggleAction } from "../../store/actions/toggleAction";
import { useDispatch } from "react-redux";

const HowToPlay = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, []);
  const dispatch = useDispatch();
  return (
    <div>
      <Header auth={getAuth} />
      {/* ===== slider section ========*/}
      <section className="slider-inner-main play-slidermain" onClick={() => dispatch(profileToggleAction(false))}>
        <div className="custom-container">
          <div className="inner-slidrbx">
            <div className="row align-items-center">
              <div className="col-md-6 one">
                <div className="left-texsldr">
                  <div className="subtitle-bx">
                    <span className="smal-line" />
                    <span className="subtitle">HOW TO PLAY</span>
                  </div>
                  <div className="heading-topp">
                    <h1 className="title-large">IT’S SHOW TIME!</h1>
                    <p className="medium-text box-space">
                      Learn about our user friendly trading dashboard, how to display charts, item, oscillators, buy and sell, close position and manage your account. On Fx Rumble, funds are virtual. Pay the fee, enter the tournament and start play!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 two">
                <div className="right-pic-sldr">
                  <img src={play_slider1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-btom">
          <img src={shape_bg_blue} alt="" />
        </div>
      </section>
      {/* ===== slider section end========*/}
      {/* <section className="play-accord-section">
        <div className="custom-container">
          <div className="playmain-box">
            <div className="heading-main">
              <h4>
                <span className="title-medium white-br-botm">
                  Lorem ipsum dolor
                </span>
              </h4>
            </div>
            <div className="new-plyaccord">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <button
                      className="btn btn-link acord-btnn"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Sed do eiusmod tempor incididunt ut labore
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        rveprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <button
                      className="btn btn-link collapsed acord-btnn"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Excepteur sint occaecat cupidatat non proident
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        rveprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <button
                      className="btn btn-link collapsed acord-btnn"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Sunt in culpa qui officia deserunt
                    </button>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        rveprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingfour">
                    <button
                      className="btn btn-link collapsed acord-btnn"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsefour"
                      aria-expanded="false"
                      aria-controls="collapsefour"
                    >
                      Quis nostrud exercitation ullamco laboris
                    </button>
                  </div>
                  <div
                    id="collapsefour"
                    className="collapse"
                    aria-labelledby="headingfour"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        rveprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingfive">
                    <button
                      className="btn btn-link collapsed acord-btnn"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsefive"
                      aria-expanded="false"
                      aria-controls="collapsefive"
                    >
                      Sed do eiusmod tempor incididunt ut labore
                    </button>
                  </div>
                  <div
                    id="collapsefive"
                    className="collapse"
                    aria-labelledby="headingfive"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        rveprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingsix">
                    <button
                      className="btn btn-link collapsed acord-btnn"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsesix"
                      aria-expanded="false"
                      aria-controls="collapsesix"
                    >
                      Sed do eiusmod tempor incididunt ut labore
                    </button>
                  </div>
                  <div
                    id="collapsesix"
                    className="collapse"
                    aria-labelledby="headingsix"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        rveprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-play-b">
        <div className="pooker-play-cards">
          <div className="custom-container">
            <div className="pooker-play-cardbx">
              <div className="row">
                <div className="col-sm-6 col-lg-4 one">
                  <div className="poker-card-body">
                    <div className="pokker-pic-bx poker-picc">
                      <img src={play1} alt="" />
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        Consectetur adipiscing elit
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do Eiusmod empor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 two">
                  <div className="poker-card-body">
                    <div className="pokker-pic-bx poker-picc">
                      <img src={play2} alt="" />
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">Totam rem aperiam</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do Eiusmod empor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 three">
                  <div className="poker-card-body">
                    <div className="pokker-pic-bx poker-picc">
                      <img src={play3} alt="" />
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">Nemo enim ipsam voluptatem</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do Eiusmod empor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 one">
                  <div className="poker-card-body">
                    <div className="pokker-pic-bx poker-picc">
                      <img src={play5} alt="" />
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        Consectetur adipiscing elit
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do Eiusmod empor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 two">
                  <div className="poker-card-body">
                    <div className="pokker-pic-bx poker-picc">
                      <img src={play6} alt="" />
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">Nemo enim ipsam voluptatem</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do Eiusmod empor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 three">
                  <div className="poker-card-body">
                    <div className="pokker-pic-bx poker-picc">
                      <img src={play4} alt="" />
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        Consectetur adipiscing elit
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do Eiusmod empor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="play-sectiob-e">
        <div className="play-section-bx play-section-cardbxx">
          <div className="custom-container">
            <div className="main-playbx-card">
              <div className="heading-main">
                <h4>
                  <span className="title-medium white-br-botm">
                    FX RUMBLE FEATURES
                  </span>
                </h4>
              </div>
              <div className="content-headingbx">
                <p>
                  Play on Fx Rumble it’s easy, here’s what you will find on the Fx Rumble Trading Dashboard, and some tips about how to use it.
                </p>
              </div>
              <div className="bx-cardplyy">
                <div className="row">
                  <div className="col-md-12 col-lg-6 one">
                    <div className="cardbx-main-plyy">
                      <div className="playcard-body">
                        <div className="leftcard-bx">
                          <div className="iconcard-bx">
                            <img src={stock} alt="" />
                          </div>
                        </div>
                        <div className="right-content-bx">
                          <div className="box-content">
                            <h4>Chart style</h4>
                            <p>
                              We offer all the possible trading charts: line, area, bars, candlestick, renko, kagi, point and figure. Time Frames, colours and historical data are all customizable
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 two">
                    <div className="cardbx-main-plyy">
                      <div className="playcard-body">
                        <div className="leftcard-bx">
                          <div className="iconcard-bx">
                            <img src={trading} alt="" />
                          </div>
                        </div>
                        <div className="right-content-bx">
                          <div className="box-content">
                            <h4>Plenty of tools and indicators</h4>
                            <p>
                              On the Fx Rumble trading dashboard, you will find all the tools and indicators that professional traders use to analyze and trade on the financial markets.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 three">
                    <div className="cardbx-main-plyy">
                      <div className="playcard-body">
                        <div className="leftcard-bx">
                          <div className="iconcard-bx">
                            <img src={stock_market} alt="" />
                          </div>
                        </div>
                        <div className="right-content-bx">
                          <div className="box-content">
                            <h4>Instant Buy & Sell</h4>
                            <p>
                              Trade on Fx Rumble is so easy! Trades works at the speed of light. No slippage, no spreads, no delays. Buy, Sell, Close position at the exact price you want. Anytime!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 four">
                    <div className="cardbx-main-plyy">
                      <div className="playcard-body">
                        <div className="leftcard-bx">
                          <div className="iconcard-bx">
                            <img src={speech_bubble} alt="" />
                          </div>
                        </div>
                        <div className="right-content-bx">
                          <div className="box-content">
                            <h4>Real Time Ranking and Wins</h4>
                            <p>
                              Check the ranking in real time, end the tournament in the first positions and get your prize.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 five">
                    <div className="cardbx-main-plyy">
                      <div className="playcard-body">
                        <div className="leftcard-bx">
                          <div className="iconcard-bx">
                            <img src={increasing_bar_graph} alt="" />
                          </div>
                        </div>
                        <div className="right-content-bx">
                          <div className="box-content">
                            <h4>All your statistics</h4>
                            <p>
                              Check all your and other users statistics. Analyze your activity and improve yourself.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 six">
                    <div className="cardbx-main-plyy">
                      <div className="playcard-body">
                        <div className="leftcard-bx">
                          <div className="iconcard-bx">
                            <img src={time} alt="" />
                          </div>
                        </div>
                        <div className="right-content-bx">
                          <div className="box-content">
                            <h4>Superfast Tournaments</h4>
                            <p>
                              If you love to play massively and fast, on Fx Rumble you will find 15 minutes turbo tournaments.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="articles-main">
        <div className="custom-container">
          <div className="video-tutorials-bx">
            <div className="heading-main">
              <h4>
                <span className="title-medium white-br-botm">
                  VIDEO TUTORIAL
                </span>
              </h4>
            </div>
            <div className="content-bxx-subtitle">
              <p>
                Here’s some video we selected for you. Get some extra insights
                about trading and strategies.
              </p>
            </div>
            <div className="articles-cards video-tutorials-cards">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-4 one">
                  <div className="videotut-card-body">
                    <div className="pokker-pic-bx vdotut-piccbx">
                      <img src={video_tut1} alt="" />
                      <div className="playicon-bxx">
                        <i className="fas fa-play" />
                      </div>
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        Setting the Trading Dashboard
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 two">
                  <div className="videotut-card-body">
                    <div className="pokker-pic-bx vdotut-piccbx">
                      <img src={video_tut2} alt="" />
                      <div className="playicon-bxx">
                        <i className="fas fa-play" />
                      </div>
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        Enter a tournament or create your own
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 three">
                  <div className="videotut-card-body">
                    <div className="pokker-pic-bx vdotut-piccbx">
                      <img src={video_tut3} alt="" />
                      <div className="playicon-bxx">
                        <span className="icn-playbx">
                          <i className="fas fa-play" />
                        </span>
                      </div>
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        How to analyze your statistics
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 four">
                  <div className="videotut-card-body">
                    <div className="pokker-pic-bx vdotut-piccbx">
                      <img src={video_tut4} alt="" />
                      <div className="playicon-bxx">
                        <i className="fas fa-play" />
                      </div>
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        Watch a tournament simulation
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 four">
                  <div className="videotut-card-body">
                    <div className="pokker-pic-bx vdotut-piccbx">
                      <img src={video_tut5} alt="" />
                      <div className="playicon-bxx">
                        <i className="fas fa-play" />
                      </div>
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">How the tournaments work</h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 four">
                  <div className="videotut-card-body">
                    <div className="pokker-pic-bx vdotut-piccbx">
                      <img src={video_tut6} alt="" />
                      <div className="playicon-bxx">
                        <i className="fas fa-play" />
                      </div>
                    </div>
                    <div className="pokker-content">
                      <h5 className="card-title">
                        How to register, deposit, withdrawal
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default HowToPlay;
