import React from "react";
import sahpe_bg_blue from "../../images/sahpe-bg-blue.svg";
import cash_withdrawal_nw from "../../images/cash-withdrawal-nw.svg";
import deposit_sec from "../../images/deposit-sec.svg";
import secure_shield from "../../images/secure-shield.svg";
import pic_act from "../../images/pic-act.svg";
import Header from "../Header";
import Footer from "../Footer";
import { getAuth } from "../../Routes/Routes";
import ScrollToTop from "../../hooks/ScrollToTop";
import { profileToggleAction } from "../../store/actions/toggleAction";
import { useDispatch } from "react-redux";

const DepositsWithdrawls = () => {
  const dispatch = useDispatch();
  return (
    <>
      <Header auth={getAuth} />
      <section
        className="slider-inner-main play-slidermain"
        onClick={() => dispatch(profileToggleAction(false))}
      >
        <div className="custom-container">
          <div className="inner-slidrbx">
            <div className="row align-items-center">
              <div className="col-md-6 one">
                <div className="left-texsldr">
                  <div className="subtitle-bx">
                    <span className="smal-line"></span>
                    <span className="subtitle">DEPOSITS AND WITHDRAWALS</span>
                  </div>
                  <div className="heading-topp">
                    <h1 className="title-large">GET YOUR ACCOUNT FOR FREE</h1>
                    <p className="medium-text box-space">
                      Open and manage your account safely on Fx Rumble. We are
                      not dealers and we don’t manage your funds directly. Our
                      banking system is provided by Stripe and it assures you
                      the best experience. Soon it will be possible to use your
                      cryptos and the FXRUMBLE Token.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 two">
                <div className="right-pic-sldr">
                  <img src={pic_act} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-btom">
          <img src={sahpe_bg_blue} alt="" />
        </div>
      </section>
      <section className="play-accord-section deposit-withd-bx">
        <div className="container">
          <div className="playmain-box">
            <div className="heading-main">
              <h4>
                <span className="title-medium white-br-botm">
                  ACCEPTED PAYMENTS
                </span>
              </h4>
              <p>
                We accept 10+ currencies and dozen of popular payment methods
                all over the world. With our banking system we assure fast and
                safe deposits via card or bank transfer.
              </p>
            </div>
            <div className="deposit-tablebx">
              <div className="deposit-table-main">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Visa</th>
                      <th scope="col">Mastercard</th>
                      <th scope="col">Credit Card</th>
                      <th scope="col">Debit Card</th>
                      <th scope="col">Direct Bank Transfer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr className="tble-topblnk">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr> */}
                    <tr>
                      <th scope="row">Deposit</th>
                      <td className="chk-icon">
                        <span className="icon-table">
                          <i className="fas fa-check"></i>
                        </span>
                      </td>
                      <td className="chk-icon">
                        <span className="icon-table">
                          <i className="fas fa-check"></i>
                        </span>
                      </td>
                      <td className="chk-icon">
                        <span className="icon-table">
                          <i className="fas fa-check"></i>
                        </span>
                      </td>
                      <td className="chk-icon">
                        <span className="icon-table">
                          <i className="fas fa-check"></i>
                        </span>
                      </td>
                      <td className="closee-icon">
                        <span className="icon-table">
                          <i className="fas fa-times"></i>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Cashout</th>
                      <td className="closee-icon">
                        <span className="icon-table">
                          <i className="fas fa-times"></i>
                        </span>
                      </td>
                      <td className="closee-icon">
                        <span className="icon-table">
                          <i className="fas fa-times"></i>
                        </span>
                      </td>
                      <td className="closee-icon">
                        <span className="icon-table">
                          <i className="fas fa-times"></i>
                        </span>
                      </td>
                      <td className="closee-icon">
                        <span className="icon-table">
                          <i className="fas fa-times"></i>
                        </span>
                      </td>
                      <td className="chk-icon">
                        <span className="icon-table">
                          <i className="fas fa-check"></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="deposit-limits">
              <div className="limit-boxx">
                <h5>DEPOSIT LIMIT</h5>
                <p>
                  We offer you the chance to set you deposit limit on a daily,
                  weekly and monthly base. Once you set it, you will be unable
                  to change it for 24 hours (daily base), one week (weekly base)
                  and one month (monthly base). Complete the KYC to be ready to
                  operate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="rumble-markets safe-secure-mainbx">
        <div className="container">
          <div className="rumble-market-box">
            <div className="heading-main">
              <h4>
                <span className="title-medium white-br-botm">
                  Safe and Secure Deposits
                </span>
              </h4>
            </div>
            <div className="market-cards">
              <div className="row">
                <div className="col-md-6 col-lg-4 one">
                  <div className="market-cardbody">
                    <div className="market-cardinner">
                      <div className="iconbx iconsky">
                        <img src={secure_shield} alt="" />
                      </div>
                      <div className="content-box">
                        <h5 className="meduim-content">
                          Secure Account Segregation
                        </h5>
                        <p>
                          a segregated bank account keep your funds separate
                          from the company’s own money. Holding your funds
                          within a separate account avoid any chance to
                          interfere or mishandling it and grant you the safest
                          experience
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-4 two">
                  <div className="market-cardbody">
                    <div className="market-cardinner">
                      <div className="iconbx iconred">
                        <img src={deposit_sec} alt="" />
                      </div>
                      <div className="content-box">
                        <h5 className="meduim-content">Set Deposit Limits</h5>
                        <p>
                          set your daily, weekly and monthly limits to set your
                          budget and control the amount of money deposited on
                          your account
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-4 three">
                  <div className="market-cardbody">
                    <div className="market-cardinner">
                      <div className="iconbx iconyellow">
                        <img src={cash_withdrawal_nw} alt="" />
                      </div>
                      <div className="content-box">
                        <h5 className="meduim-content">
                          Quick and Easy Withdrawals
                        </h5>
                        <p>
                          with our banking system we offer the safest and
                          fastest withdrawals for each payment method chosen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-btom">
          <img src={sahpe_bg_blue} alt="" />
        </div>
      </section>
      <section className="withdrawel-section-g">
        <div className="box-sectiong">
          <div className="container">
            <div className="new-withdr-bxx">
              <div className="heading-main">
                <h4>
                  <span className="title-medium white-br-botm">
                    OUR COMMITMENT
                  </span>
                </h4>
              </div>
              <div className="botm-content-withdrl">
                <p>
                  At Fx Rumble we aim to offer the fairest game and best
                  experience. That means we put the safety of your funds in the
                  first place. The funds are segregated and we lay on stripe to
                  guarantee the fastest execution of deposits and withdrawals.
                  Set your deposit limit wisely and be ready to play. For any
                  instance our customer’s support will be at your full disposal.
                  We are not dealer and we would love to see you thrive on Fx
                  Rumble.
                </p>
                {/* <p>
                  Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                  amet, consectetur, adipisci velit, sed quia non numquam eius
                  modi tempora incidunt ut labore et dolore magnam aliquam
                  quaerat voluptatem. ullam corporis suscipit laboriosam
                  aliquid.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default DepositsWithdrawls;
