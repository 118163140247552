import React from "react";
import { getAuth } from "../../Routes/Routes";
import Header from "../Header";
import loose from "../../images/loose.png";
import trophy from "../../images/trophy.png";
import logo from "../../images/fx-club-logo.png";
import rxicon from "../../images/rxicon.svg";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { getTournamentResult } from "../../store/actions/tournamentActions";
import { Link } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import { profileToggleAction } from "../../store/actions/toggleAction";

const WonTournament = (props) => {

  const tournament = useSelector((state) => state.tournament);
  let { participants,singleTournament, top_traders } = tournament;
 

  
  let { id, name } = props.match.params;
  const dispatch = useDispatch();
  React.useEffect(async () => {
    dispatch(getTournamentResult({ payload: id, name: name })); 
  }, []);

  return (
    <>
      <Header auth={getAuth} />
      <section className="innerpage-bx won-tournamentt" onClick={()=>dispatch(profileToggleAction(false))}>
        <div className="winning-cardbx">
          <div className="winning-box-main">
            <div className="clublogo">
              <img src={logo} alt="" />
            </div>
            <div className="won-pic">
              <>{ participants && participants[0]?.result == 'won' ? <img src={trophy} alt="" /> : <img src={loose} alt="" />}</>
            </div>
            <>
            {participants && participants[0]?.result == 'won' &&
             <div className="won-content">
             <div className="subtitle-tex"><>Congratulations</> </div>
             <div className="rank">
               {participants && participants[0]?.fullName} 
             </div>
            </div>
            }
            </>
            <>
            {participants && participants[0]?.result == 'lost' &&
             <div className="lost-content">
             <div className="subtitle-tex"><> Sorry, {participants && participants[0]?.fullName} </> </div>
             </div>
            }
            </>
        
            <div className="wininng-price">
              <div className="smal-texx">You've {participants && participants[0]?.result} {singleTournament ?.type == "headsUp" ? "Heads Up" : "Club" } tournament</div>
              {/* <div className="price-won-detail">
                <span className="text">WINNINGS:</span>
                <span className="fx-lg-smal">
                  <img src={rxicon} alt="" />
                </span>
                <span className="total-price-won">
                  {participants && participants[0]?.other_bal}
                </span>
              </div> */}
            </div>
            <div className="going-tournament">
              <Link
                to="/all-tournaments"
                className="btn-org btn-bxx btn-primaryy"
              >
                On Going Tournaments
              </Link>
            </div>
          </div>
        </div>
        <></>
        { participants && participants[0] && participants[0].top_traders !== undefined && 
           <div className="top-trades">
           <div className="tardes-list">
             <div className="title">
             <>{ participants && participants[0] && participants[0]?.top_traders.length > 5  ? 'Top 5 Traders' : 'Top Traders' }</>
             </div>
             <div className="tardes-listing">
               <ul className="trades-list-top">
                 {participants[0] &&
                   participants[0].top_traders ?.map((participant, index) => {
                     return (
                       <li key={`l-${index}`}>
                         <div className="card-listbx tardes-cardlistt">
                           <div className="imagbx">
                             <img src="" alt="" />
                           </div>
                           <div className="name-cardbx">
                             <div className="name">{participant.fullName}</div>
                             <div className="point-leaderbrd">
                               <span className="iconfx">
                                 <img src={rxicon} alt="" />
                               </span>
                               <span className="up-price">
                                 {participant.points}
                               </span>
                             </div>
                           </div>
                           <div className="count-num">
                             {/* <span className="total-num">1</span> */}
                           </div>
                         </div>
                       </li>
                     );
                   })}
               </ul>
             </div>
             <div className="bottom-btngo">
               <ul className="gohome-btnlst">
                 <li>
                   <Link to="/" className="loginbtn btn-bxx btn-primaryy gohome">
                     Go to Home
                   </Link>
                 </li>
                 <li>
                   <Link to="#" className="btn-org btn-bxx btn-primaryy">
                     View wallet
                   </Link>
                 </li>
               </ul>
             </div>
           </div>
         </div>
        } 
       <></>
       
      </section>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default WonTournament;
