const initialState = {
  loading: false,
  cards: [],
  buyPlanData: {
    status: true,
  },
  banks:[]
};

export const StripeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "ADD_CARD":
      return { ...state, loading: false };
    case "ADD_CARD_ERROR":
      return { ...state, loading: false };
    case "GET_CARD":
      return { ...state, cards: payload.payment_methods, loading: false };
    case "GET_CARD_ERROR":
      return { ...state, loading: false };
    case "BUY_PLAN":
      return { ...state, loading: false, buyPlanData: payload };
    case "BUY_PLAN_ERROR":
      return { ...state, loading: false };
    case "LOADING":
      return { ...state, loading: payload };
    case "ADD_BANK":
        return { ...state, loading: false, bankDetail:payload };
    case "ADD_BANK_ERROR":
        return { ...state, loading: false, bankError:payload };
    case "GET_BANK":
      return { ...state, banks: payload, loading: false };
    case "GET_BANK_ERROR":
      return { ...state, banks: [], loading: false };
    case "VERIFY":
      return { ...state, loading: false };
    case "VERIFY_BANK_ERROR":
      return { ...state,loading: false };
    default:
      return state;
  }
};
