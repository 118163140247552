const initialState = {
  tournaments: [],
  adminTournament: [],
  newJoinedTournament: null,
  singleTournament: {},
  coinCredit: false,
  participants: [],
  isActive: true,
};

export const tournamentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_TOURNAMENT":
      return { ...state, tournaments: payload.userTournament, adminTournament: payload.adminTournament };
    case "CREDIT_COIN":
      return { ...state, coinCredit: true };
    case "CREDIT_COIN_ERROR":
      return { ...state, coinCredit: false };
    case "GET_PARTICIPANTS":
      return { ...state, participants: payload };
    case "GET_PARTICIPANTS_ERROR":
      return { ...state, participants: [] };
    case "GET_SINGLE_TOURNAMENT":
      return { ...state, singleTournament: payload };
    case "GET_SINGLE_TOURNAMENT_ERROR":
      return { ...state, singleTournament: {} };
    case "GET_TOURNAMENT_ERROR":
      return { ...state, tournaments: [] };
    case "IS_ACTIVE":
      return { ...state, isActive: payload };
    case "IS_NOT_ACTIVE":
      return { ...state, isActive: payload };
    case "IS_ACTIVE_ERROR":
      return { ...state };

    case "JOIN_TOURNAMENT":
      return { ...state, newJoinedTournament: payload };
    default:
      return state;
  }
};
