import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { StripeReducer } from "./stripeReducer";
import { profileToggleReducer } from "./toggleReducer";
import { tournamentReducer } from "./tournamentReducer";

export default combineReducers({
  user: authReducer,
  tournament: tournamentReducer,
  stripe:StripeReducer,
  profileToggle:profileToggleReducer
});
