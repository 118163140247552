import React from "react";
import logo from "../../images/logo.svg";
import iconmonstr_email from "../../images/iconmonstr-email.svg";
import right_arrow from "../../images/right-arrow.svg";
import { Link, useHistory } from "react-router-dom";
import Header from "../Header";
import { getAuth } from "../../Routes/Routes";
import Footer from "../Footer";
import ScrollToTop from "../../hooks/ScrollToTop";
import { postRequest }  from '../../Routes/axios'
import { profileToggleAction } from "../../store/actions/toggleAction";
import { useDispatch } from "react-redux";
const ForgotPassword = () => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(null);
  const history = useHistory();
  const dispatch=useDispatch();
  const handleReset = async () => {
    try {
      if (typeof email === "undefined") return false;

    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(email)) {
      setError("Please enter valid email address.");
      return false;
    }

    let res = await postRequest(`/reset-link`,{ email: email })
     
    history.push(`/link-sent`);
    } catch (err) {
      console.log('\n error in forgot:', err.message || err)
      setError(err.message || 'something went wrong');
    }
  };

  return (
    <>
      <Header auth={getAuth} />
      <div className="wrapper" onClick={()=>dispatch(profileToggleAction(false))}>
        <section className="login-box">
          <div className="container-login-bx">
            <div
              className="section-main-cardd"
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
            >
              <div className="row">
                <div className="col-md-6 col-lg-7 one">
                  <div className="welcm-bx">
                    <h4 className="medium-subtitle">Welcome to</h4>
                    <h1 className="title-large">FX RUMBLE</h1>
                    <p>Sed do eiusmod tempor incididunt</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 two">
                  <form>
                    <div className="login-form-new">
                      <div className="form-logn-bx">
                        <div className="logo-card">
                          <Link to="">
                            <img src={logo} alt="" />
                          </Link>
                        </div>
                        <div className="login-detail-frm form-layoutt">
                          <div className="headg-bx-lgn forgot-pssw">
                            <h4 className="heading-frmm">Forgot Password</h4>
                            <p className="text">
                              Enter your email address to get reset password
                              link
                            </p>
                          </div>

                          <div className="form-filedbx">
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <input
                                  type="email"
                                  className="form-control"
                                  id="exampleInputEmail"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  aria-describedby="emailHelp"
                                  placeholder="Email"
                                />
                                <span className="icon-form-fld">
                                  <img src={iconmonstr_email} alt="" />
                                </span>
                              </div>
                              {error && <span className="error">{error}</span>}
                            </div>

                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <button
                                  type="button"
                                  name="button"
                                  onClick={handleReset}
                                  className="btn-logn-frm btn-org"
                                >
                                  <span className="btn-tex">
                                    Get reset link
                                  </span>
                                </button>
                              </div>
                            </div>

                            <div className="back-lnk">
                              <Link to="/login">
                                <span className="icon-right">
                                  <img src={right_arrow} alt="" />
                                </span>
                                Back
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer /> <ScrollToTop />
    </>
  );
};

export default ForgotPassword;
