import { fetchEndpointData, postRequest }  from '../../Routes/axios'
export const addCard = (paymentMethod, token) => {
  return async (dispatch) => {
    try {
      let res = await postRequest(`/stripe/payment-methods`, {
        paymentMethod: paymentMethod,
        token: token,
        methodType:'card'
      });
      return dispatch({
        type: "ADD_CARD",
        payload: {},
      });
    } catch (error) {
      return dispatch({
        type: "ADD_CARD_ERROR",
        payload: {
          error: error,
        },
      });
    }
  };
};
export const Loading = (payload) => {
  return {
    type: "LOADING",
    payload: payload,
  };
};
export const getCard = () => {
  return async (dispatch) => {
    try {
      const response = await fetchEndpointData('/stripe/payment-methods')
      if(response && response.data) {
        return dispatch({
          type: "GET_CARD",
          payload: response.data,
        });
      }
    } catch (error) {
      return dispatch({
        type: "GET_CARD_ERROR",
        payload: {
          error: error,
        },
      });
    }
  };
};

export const buyPlan = (payloadData) => {
  return async (dispatch) => {
    try {
      return dispatch({
          type: "BUY_PLAN",
          payload: payloadData,
        });
    } catch (error) {
      return dispatch({
        type: "BUY_PLAN_ERROR",
        payload: {
          error: error,
        },
      });
    }
  };
};
export const addBankDetail = (token)=> {
  return async(dispatch)=> {
    try {
      let res = await postRequest(`/stripe/add-customer-bank`, {
        token: token,
        methodType:'bank'
      });
      console.log('\n res', res)
      return dispatch({
        type: "ADD_BANK",
        payload: {},
      });
    } catch(error) {
      if(error?.response?.data) {
        return dispatch({
          type: "ADD_BANK_ERROR",
          payload: {
            error: error?.response?.data,
          },
        });
      } else {
        return dispatch({
          type: "ADD_BANK_ERROR",
          payload: {
            error: error,
          },
        });
      }
    }
  }
}
export const fetchAllBank = ()=> {
  return async(dispatch)=> {
    try {
      let response = await fetchEndpointData(`/stripe/get-customer-banks`);
      if(response && response.data) {
        let bankAccounts = response.data.bankAccount && response.data.bankAccount.data ? response.data.bankAccount?.data: []
        return dispatch({
          type: "GET_BANK",
          payload: bankAccounts,
        });
      }
    } catch(error) {
      if(error?.response?.data) {
        return dispatch({
          type: "GET_BANK_ERROR",
          payload: {
            error: error?.response?.data,
          },
        });
      } else {
        return dispatch({
          type: "GET_BANK_ERROR",
          payload: {
            error: error,
          },
        });
      }
    }
  }
}
export const verifyBankDetail = (data)=> {
  return async(dispatch)=> {
    try {
      let res = await postRequest(`/stripe/verify-customer-bank`, data);
      return dispatch({
        type: "VERIFY",
        payload: {},
      });
    } catch(error) {
      if(error?.response?.data) {
        return dispatch({
          type: "VERIFY_BANK_ERROR",
          payload: {
            error: error?.response?.data,
          },
        });
      } else {
        return dispatch({
          type: "VERIFY_BANK_ERROR",
          payload: {
            error: error,
          },
        });
      }
    }
  }
}
