import React from "react";
import "./AddBankDetail.css";
import {
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Footer from "../Footer";
import { getAuth } from "../../Routes/Routes";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import { addBankDetail, Loading, fetchAllBank } from "../../store/actions/stripeActions";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form'
import { profileToggleAction } from "../../store/actions/toggleAction";


const config = require(`../../enviorment/${process.env.NODE_ENV}.jsx`).config;
const stripePromise = loadStripe(
  config.stipe_key
);


const MyBankForm = () => {
  const history = useHistory();
  const stripeState = useSelector((state) => state.stripe);
  let { loading, banks } = stripeState;
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  React.useEffect(() => {
    dispatch(fetchAllBank());
  }, []);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      country:'', //'US',
     currency:'', //'USD',
     accountHolderName: '', //'Rohan',
     accountHolderType:'', //'individual',
     routingNumber:'', //'110000000',
     accountNumber:'' //'000123456789'
      
    },
  })
  watch();
  const onSubmit = async (data, event) => {
    try {
      dispatch(Loading(true));
        if (!stripe || !elements) return false;
        const user = JSON.parse(localStorage.getItem("FXuser"));
        let bankPayload = {
          country:data.country,
          currency:data.currency,
          account_holder_name:data.accountHolderName,
          account_holder_type:data.accountHolderType,
          routing_number:data.routingNumber,
          account_number:data.accountNumber
        }
        
        let token = await stripe.createToken('bank_account', bankPayload);
        console.log('\n token detail:', token)
      dispatch(addBankDetail(token.token.id)).then((res) => {
          if(res?.payload?.error?.isError) {
            let errorMessage = (res?.payload?.error?.errorMessage) ? res?.payload?.error?.errorMessage : 'Something went wrong during add bank account.' 
            toast.error(errorMessage)
          } else{
            toast.success("Bank Added Successfully.")
            return history.push("/verify-bank");
          }
          dispatch(Loading(false))
      })
    } catch (err) {
      console.log('\n error in submit:', err.message || err)
    }
  };
  

  const onError = async (errors, event) => {
    try {
      console.log('\n on error:', errors, '\n event:', event?.target) 
    } catch (error) {
      console.log('\n error in onError fun:', error.message || error)
    }
  }
  

  return (
    <>
      <Header auth={getAuth} />
      <div className="wrapper" onClick={()=>dispatch(profileToggleAction(false))}>
        <section className="login-box">
          <div className="container-login-bx card-conatiner">
            <div
              className="section-main-cardd"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              <div className="row">
                <div className="col-md-12">
                  <form>
                    <div className="login-form-new ">
                      <div className="form-logn-bx">
                        <div className="login-detail-frm form-layoutt pt-0">
                          <div className="headg-bx-lgn ">
                            <h4 className="heading-frmm">Add Bank</h4>
                          </div>
                          {/* <div className="form-filedbx"> */}
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                              <select
                                className="form-control"
                                id="country"
                                {...register('country', {
                                  required: 'Please select country',
                                })}
                                value={getValues('country')}
                                
                              >
                                <option value="">Select country</option>
                                <option value="US">US</option>
                              </select>
                              </div>
                              {errors.country && errors.country.message &&  <span className="error">{errors.country.message}</span>}
                            </div>
                            <div className="form-group">
                                <div className="frm-bx-fldn">
                                  <select
                                      className="form-control"
                                      id="currency"
                                      {...register('currency', {
                                        required: 'Please select currency',
                                      })}
                                      value={getValues('currency')}
                                    >
                                    <option value="">Select currency</option>
                                    <option value="USD">USD</option>
                                  </select>
                                </div> 
                                {errors.currency && errors.currency.message && <span className="error">{errors.currency.message}</span>}
                            </div>
                            <div className="form-group">
                                <div className="frm-bx-fldn">
                                <input
                                  type="text"
                                  {...register('accountHolderName', {
                                    required: 'Please enter account holder name',
                                  })}
                                  value={getValues('accountHolderName')}
                                  className="form-control"
                                  id="accountHoldername"
                                  placeholder="account holder Name"
                                />
                                </div>
                                {errors.accountHolderName && errors.accountHolderName.message && <span className="error">{errors.accountHolderName.message}</span>}
                            </div>
                            <div className="form-group">
                                <div className="frm-bx-fldn">
                                  <select
                                      className="form-control"
                                      id="currency"
                                      {...register('accountHolderType', {
                                        required: 'Please select accont holder type',
                                      })}
                                      value={getValues('accountHolderType')}
                                    >
                                    <option value="">Select account Holder Type</option>
                                    <option value="individual">Individual</option>
                                    <option value="company">Company</option>
                                  </select>
                                </div>
                                {errors.accountHolderType && errors.accountHolderType.message && <span className="error">{errors.accountHolderType.message}</span>}
                            </div>
                            <div className="form-group">
                                <div className="frm-bx-fldn">
                                 <input
                                  type="text"
                                  {...register('routingNumber', {
                                    required: 'Please enter routing number',
                                  })}
                                  value={getValues('routingNumber')}
                                  className="form-control"
                                  id="routingNumber"
                                  placeholder="routing number"
                                />
                                </div>
                                {errors.routingNumber && errors.routingNumber.message && <span className="error">{errors.routingNumber.message}</span>}
                            </div>
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                              <input
                                  type="text"
                                  {...register('accountNumber', {
                                    required: 'Please enter account number',
                                  })}
                                  value={getValues('accountNumber')}
                                  className="form-control"
                                  id="accountNumber"
                                  placeholder="account number"
                                />
                              </div>
                                {errors.accountNumber && errors.accountNumber.message && <span className="error">{errors.accountNumber.message}</span>}
                            </div>
                            
                            <div className="form-group">
                              <div className="frm-bx-fldn">
                                <button
                                  onClick={handleSubmit(onSubmit, onError)}
                                  type="button"
                                  name="button"
                                  className="btn-logn-frm btn-org"
                                >
                                  {loading ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      Loading...
                                    </>
                                  ) : (
                                    <span className="btn-tex">
                                      {/* {banks?.length > 0 ? "Update" : "Add"} */}
                                      Add Bank
                                    </span>
                                  )}
                                </button>
                              </div>
                            </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};

const AddBankDetail = () => {
  return (
    <Elements stripe={stripePromise}>
      <MyBankForm />
    </Elements>
  );
};
export default AddBankDetail;
